import styled, { css } from 'styled-components'

import { Label } from '../text/index.style'

export const RadioCheckboxContainer = styled.div`
  gap: 0.5rem;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: flex-start;
  &,
  & > div {
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};
  }
  &:hover > div {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
  }

  flex-direction: ${({ labelPosition }) =>
    labelPosition === 'right' ? 'row' : 'row-reverse'};

  ${({ withCustomLink }) =>
    withCustomLink &&
    css`
      a {
        text-decoration: none;
        color: ${({ theme }) => theme.colors.statuses.link};
      }
    `}
`
export const RadioCheckboxLabel = styled(Label)`
  width: 100%;
  white-space: ${({ withWrap }) => (withWrap ? 'pre-wrap' : 'nowrap')};
  color: ${({ theme, disabled, value }) => {
    if (disabled) return theme.colors.text.tertiary
    if (value) return theme.colors.text.primary
    return theme.colors.text.secondary
  }};
  &:hover {
    color: ${({ theme, disabled }) =>
      disabled ? theme.colors.text.tertiary : theme.colors.text.primary};
  }
  transition: all 0.3s ease;
`
