import { createStore, applyMiddleware, compose } from 'redux'
import createSagaMiddleware from 'redux-saga'
import logger from 'redux-logger'
import { routerMiddleware } from 'connected-react-router'
import rootReducer from './reducers/'
import rootSaga from './sagas/'
import history from '../services/history'
import {
  setDataToLocalForage,
  getDataFromLocalForage,
} from '../utils/storage/storage'
import { initialState as settingsDefaultStore } from './reducers/settings'
import { initialState as mmapDefaultStore } from './reducers/mmap'
import { initialState as compareParamsDefaultStore } from './reducers/compareParams'

import { WITH_LOGGER } from '../config/config'

const sagaMiddleware = createSagaMiddleware()

const middlewaresList = WITH_LOGGER
  ? [sagaMiddleware, logger, routerMiddleware(history)]
  : [sagaMiddleware, routerMiddleware(history)]

const enhancers = compose(applyMiddleware(...middlewaresList))

const loadPersistedState = async () => {
  try {
    let persistedSettingsState = await getDataFromLocalForage('s_settings')
    let persistedMmapState = await getDataFromLocalForage('mmap')
    let persistedCompareParamsState = await getDataFromLocalForage(
      'compare_params'
    )

    if (!persistedSettingsState) {
      persistedSettingsState = { mmap: mmapDefaultStore }
      await setDataToLocalForage('s_settings', persistedSettingsState)
    }

    if (!persistedMmapState) {
      persistedMmapState = { mmap: mmapDefaultStore }
      await setDataToLocalForage('mmap', persistedMmapState)
    }

    if (!persistedCompareParamsState) {
      persistedCompareParamsState = {
        compareParams: compareParamsDefaultStore,
      }
      await setDataToLocalForage('compare_params', persistedCompareParamsState)
    }

    return {
      ...persistedSettingsState,
      ...persistedMmapState,
      ...persistedCompareParamsState,
    }
  } catch (error) {
    return {
      settings: settingsDefaultStore,
      mmap: mmapDefaultStore,
      compareParams: compareParamsDefaultStore,
    }
  }
}

const initializeStore = async () => {
  try {
    const initialStore = await loadPersistedState()
    const store = createStore(rootReducer(history), initialStore, enhancers)

    store.subscribe(() => {
      setDataToLocalForage('s_settings', {
        settings: store.getState().settings,
      })
      setDataToLocalForage('mmap', { mmap: store.getState().mmap })
      setDataToLocalForage('compare_params', {
        compareParams: store.getState().compareParams,
      })
    })
    sagaMiddleware.run(rootSaga)
    return store
  } catch (error) {
    return
  }
}

const store = await initializeStore()

export default store
export { store }
