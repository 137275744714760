import { themeOptions } from 'components/Feedback/FeedbackForm/config'
import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { store } from 'services/rest'
import { useAuth } from 'utils/auth/auth'
import { emailReg } from 'utils/common/common'

const initialState = {
  name: '',
  surname: '',
  contact: '',
  text: '',
  selectedTheme: themeOptions[0],
  showSuccessMsg: false,
  selectedModules: [],
  isEmailError: false,
  haveOuterLink: false,
  outerLinkValue: '',
  citeAgreement: false,
  companyNameAgreement: false,
  showCaptcha: false,
}

export const useFeedbackValidation = () => {
  const { t } = useTranslation()
  const [state, setState] = useState(initialState)
  const [files, setFiles] = useState([])
  const [rawFiles, setRawFiles] = useState([])
  const [errors, setErrors] = useState({})
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const [emailWarning, setEmailWarning] = useState('')

  const { isAuthenticated, isRequestsBlocked } = useAuth()

  const {
    text,
    name,
    surname,
    contact,
    showSuccessMsg,
    selectedTheme,
    selectedModules,
    isEmailError,
    haveOuterLink,
    outerLinkValue,
    citeAgreement,
    companyNameAgreement,
  } = state || {}

  const handleChange = (key, value) => {
    // email validating
    if (key === 'contact') {
      if (!value && emailWarning) setEmailWarning('')
      if (value && !emailReg.test(value)) setEmailWarning('account.emailError')
      else if (emailWarning) setEmailWarning('')
    } else if (key === 'authName') {
      setState({
        ...state,
        name: value[0] || ' ',
        surname: value[1] || ' ',
      })
      return
    }

    setState({
      ...state,
      [key]: value,
    })
  }

  const getText = () => {
    let initText = text
    if (outerLinkValue) initText = `${initText} \n url:${outerLinkValue}`
    if (citeAgreement)
      initText = `${initText} \n ${t('feedback.agreement_terms')}`
    return initText
  }

  const handleValidateCaptcha = (token) => {
    if (token) {
      setTimeout(() => handleSendFeedback(token), 500)
    }
  }

  const sendFeedback = async (token) => {
    const formData = new FormData()
    const text = getText()
    formData.append('name', name)
    formData.append('surname', surname)
    formData.append('text', text)
    formData.append('contact', contact)
    formData.append('type_id', selectedTheme.id)
    formData.append(
      'module_ids',
      selectedModules.length
        ? JSON.stringify(selectedModules)
        : JSON.stringify([])
    )
    if (rawFiles.length) {
      rawFiles.forEach((file) => formData.append('files', file))
    }

    if (!isAuthenticated) {
      formData.append('captcha_token', token)
    }

    const res = await store('/feedback', formData)
    return res
  }

  const handleSendFeedback = async (token) => {
    try {
      if (isRequestsBlocked?.current) isRequestsBlocked.current = false
      setLoading(true)
      const res = await sendFeedback(token)
      if (res.status === 200 || res.status === 201)
        handleChange('showSuccessMsg', true)
    } catch (e) {
      console.log(e)
    } finally {
      setLoading(false)
    }
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    const validationErrors = validate()
    setErrors(validationErrors)
    setIsSubmitting(true)
    if (!Object.keys(validationErrors)?.length) {
      // для не залогиненных пользователей показываем капчу
      if (!isAuthenticated) {
        setState((prev) => ({ ...prev, showCaptcha: true }))
      } else {
        handleSendFeedback()
      }
    }

    setTimeout(() => {
      setIsSubmitting(false)
      setErrors({})
    }, 3000)
  }

  const validate = () => {
    let errors = {}
    const isExpData = selectedTheme.value === 4
    const isLitSource = selectedTheme.value === 5

    if (!isAuthenticated) {
      if (!name) errors.name = 'name is required'
      if (!surname) errors.surname = 'surname is required'
      if (!contact) errors.contact = 'email is required'
    }

    if (selectedTheme.value === 1) {
      if (!text) errors.text = 'text is required'
    }
    if (
      selectedTheme.value === 2 ||
      selectedTheme.value === 3 ||
      selectedTheme.value === 6
    ) {
      if (!text) errors.text = 'text is required'

      // modules are required only for authenticated users
      if (!selectedModules?.length && isAuthenticated)
        errors.modules = 'module is required'
    }
    if (isExpData) {
      if (!text) errors.text = 'text is required'
      if (!selectedModules?.length) errors.modules = 'module is required'
    }

    if (isExpData || isLitSource) {
      if (!text) errors.text = 'text is required'
      if (isExpData && !selectedModules?.length)
        errors.modules = 'module is required'
      if (!files?.length && !outerLinkValue)
        errors.file_or_link = 'file or link must be provided'
      if ((isExpData || isLitSource) && !citeAgreement)
        errors.cite_agreement = 'provide cite agreement'
    }

    return errors
  }

  return {
    state,
    errors,
    files,
    setFiles,
    isSubmitting,
    handleChange,
    handleSubmit,
    rawFiles,
    setRawFiles,
    loading,
    emailWarning,
    handleValidateCaptcha,
  }
}
