export const contacts = [
  { id: 0, title: 'pdf.site', value: 'app.syntelly.com' },
  { id: 1, title: 'pdf.email', value: 'admin@syntelly.ru' },
  { id: 2, title: 'pdf.phone', value: '+79030025394' },
]
export const networks = {
  ru: [
    {
      id: 0,
      icon: 'youtubePdf',
      link: 'https://www.youtube.com/@syntelly',
      safari_coords: [9, 287, 5, 3],
      coords: [9, 286, 6, 4],
    },
    {
      id: 1,
      icon: 'vkPdf',
      link: 'https://vk.com/syntelly',
      safari_coords: [17, 287, 6, 4],
      coords: [18, 286, 6, 5],
    },
    {
      id: 2,
      icon: 'telegramPdf',
      link: 'https://t.me/+igPh6Exzc7xlNjEy',
      safari_coords: [26, 287, 5, 4],
      coords: [28, 286, 6, 5],
    },
  ],
  en: [
    {
      id: 0,
      icon: 'youtubePdf',
      link: 'https://www.youtube.com/@syntelly',
      safari_coords: [9, 287, 5, 3],
      coords: [9, 286, 6, 4],
    },
    {
      id: 1,
      icon: 'fbPdf',
      link: 'https://www.facebook.com/profile.php?id=61553202013089',
      safari_coords: [17, 287, 6, 4],
      coords: [18, 286, 6, 5],
    },
    {
      id: 2,
      icon: 'telegramPdf',
      link: 'https://t.me/+igPh6Exzc7xlNjEy',
      safari_coords: [26, 287, 5, 4],
      coords: [28, 286, 6, 5],
    },
  ],
}
