import styled from 'styled-components'
import { SelectContainer } from 'components/CustomSelect/index.style'

export const SelectWithLabel = styled.div`
  gap: 0.5rem;
  display: flex;
  font-size: 0.6875rem;
  flex-direction: column;
`

export const KetcherBlock = styled.div`
  gap: 0.75rem;
  display: flex;
`

export const Wrapper = styled.div`
  gap: 0px;
  width: 100%;
  display: flex;
  flex-direction: column;
`
export const Content = styled.div`
  gap: 1rem;
  display: flex;
  flex-direction: column;
  ${SelectContainer} {
    width: 100%;
  }
`
