import { Caption } from 'components/common/text/index.style'
import React from 'react'
import { useTranslation } from 'react-i18next'
import {
  Wrapper,
  LogoContainer,
  ContactsContainer,
  Contact,
  IconLink,
  IconsWrapper,
  WatermarkWrapper,
} from './index.style'
import { contacts, networks } from './config'
import Icon from 'components/Icon'
import { useGetLogoImage } from 'hooks/useGetLogoImage'
import { useTheme } from 'styled-components'

export const PdfHeader = () => {
  const { t } = useTranslation()
  const logoImage = useGetLogoImage()
  const theme = useTheme()

  return (
    <Wrapper id="mol-pdf-header" borderBottom={true}>
      <LogoContainer src={logoImage} alt="company-logo" />

      <ContactsContainer>
        {contacts.map(({ id, title, value }) => (
          <Contact key={id}>
            <Caption
              color={theme.colors.text.secondary}
              fontWeight={theme.fontWeight.medium}
              fontSize="0.625rem"
              lineHeight="0.75rem"
            >
              {t(title)}
            </Caption>
            <Caption
              fontWeight={theme.fontWeight.medium}
              fontSize="0.625rem"
              lineHeight="0.75rem"
            >
              {value}
            </Caption>
          </Contact>
        ))}
      </ContactsContainer>
    </Wrapper>
  )
}

export const PdfFooter = () => {
  const { t, i18n } = useTranslation()
  const theme = useTheme()
  const language = i18n.language
  const ua = navigator.userAgent
  const isSafari =
    /Safari/.test(ua) && !/Chrome/.test(ua) && /AppleWebKit/.test(ua)

  return (
    <Wrapper id="mol-pdf-footer" borderTop={true} withBottom={true}>
      <IconsWrapper>
        {networks[language]?.map(({ id, link, icon }) => (
          <IconLink
            key={id}
            href={link}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon
              iconType={icon}
              size={isSafari ? '1.25rem' : '1.5rem'}
              className="pdf-footer-icon"
            />
          </IconLink>
        ))}
      </IconsWrapper>
      <WatermarkWrapper>
        <Caption
          color={theme.colors.text.tertiary}
          fontWeight={theme.fontWeight.medium}
          fontSize="0.625rem"
          lineHeight="0.75rem"
        >
          {t('pdf.watermark_1')}
        </Caption>
        <Caption
          color={theme.colors.text.tertiary}
          fontWeight={theme.fontWeight.medium}
          fontSize="0.625rem"
          lineHeight="0.75rem"
        >
          {t('pdf.watermark_2')}
        </Caption>
      </WatermarkWrapper>
      <Caption
        color={theme.colors.text.tertiary}
        fontWeight={theme.fontWeight.medium}
        fontSize="0.625rem"
        lineHeight="0.75rem"
      >
        {t('pdf.copyright')}
      </Caption>
    </Wrapper>
  )
}
