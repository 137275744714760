import { useState, useEffect, useContext, createContext, useRef } from 'react'
import { fetch, store, axiosInstances } from '../../services/rest'
import { readStorage, removeFromStorage, setStorage } from '../storage/storage'
import { useTranslation } from 'react-i18next'
import {
  checkInterruptSession,
  setExpiredRefreshDate,
} from 'utils/common/common'

export const AuthContext = createContext(null)
export const useAuth = () => useContext(AuthContext)

export const AuthProvider = ({ children }) => {
  const [isAuthenticated, setIsAuthenticated] = useState(false)
  const [userInfo, setUserInfo] = useState(null)
  const [user] = useState({})
  const [role, setRole] = useState({})
  const [loading, setLoading] = useState(false)
  const isRequestsBlocked = useRef(null)
  const { t } = useTranslation()

  useEffect(() => {
    const initAuth = async () => {
      try {
        setLoading(true)
        await fetch('/profile')
        setIsAuthenticated(true)
        setLoading(false)
      } catch (error) {
        setLoading(false)
        if (error.response) {
          if (error.response.status === 401) {
            deleteToken()
          }
        }
        console.log(error)
      }
    }
    if (getToken()) {
      initAuth()
    }
  }, [])

  useEffect(() => {
    axiosInstances.forEach((axiosInstance) => {
      if (!axiosInstance.interceptors.response.handlers.length) {
        axiosInstance.interceptors.request.use((config) => {
          return isRequestsBlocked?.current
            ? Promise.reject('Requests blocked')
            : config
        })

        axiosInstance.interceptors.response.use(
          (response) => response,
          async (error) => {
            if (isRequestsBlocked.current) return Promise.reject(error)
            const errorMessage =
              error.response?.data?.result?.error_message || ''

            if (
              error.response?.status === 403 &&
              errorMessage === 'User is not authenticated'
            ) {
              try {
                const { data } = await store('/profile/token/refresh', {
                  refresh_token: getRefreshToken(),
                })

                const newToken = data?.access_token || ''
                if (newToken) {
                  putToken(newToken)
                  setExpiredRefreshDate(data?.refresh_token_expires_in)
                }
              } catch (refreshTokenError) {
                if (refreshTokenError.response?.status === 400) {
                  isRequestsBlocked.current = true
                }

                if (!checkInterruptSession()) logout()
              }
            }

            return Promise.reject(error)
          }
        )
      }
    })

    return () => {
      isRequestsBlocked.current = false
    }
  }, [])

  const getToken = () => {
    const res = readStorage('s_token')
    return res
  }

  const getRefreshToken = () => {
    const res = readStorage('refresh_token')
    return res
  }

  const putToken = (token) => {
    setStorage('s_token', token)
  }

  const putRefreshToken = (token) => {
    setStorage('refresh_token', token)
  }

  const deleteToken = () => {
    removeFromStorage('s_token')
  }

  const setAdminRole = (currentRole) => {
    setStorage('role', currentRole)
  }

  const setAdminCustomerId = (id) => {
    setStorage('customer_id', id)
  }

  const setLicenseId = (id) => {
    setStorage('license_id', id)
  }

  const deleteAdminRole = () => {
    removeFromStorage('role')
  }

  const deleteAdminCustomerId = () => {
    removeFromStorage('customer_id')
  }

  const deleteCustomerDatasetsAccess = () => {
    removeFromStorage('showCustomerDatasets')
  }

  const deleteLicenseId = () => {
    removeFromStorage('license_id')
  }

  const login = async (mail, password) => {
    try {
      isRequestsBlocked.current = false
      setLoading(true)
      if (mail && typeof mail === 'string') mail = mail.toLowerCase()
      const { data } = await store('/profile/login', { mail, password })
      setUserInfo(data)
      putToken(data.access_token)
      putRefreshToken(data.refresh_token)
      setRole(data)
      setIsAuthenticated(true)
      setLoading(false)
      setAdminRole(data.customer_role)
      setAdminCustomerId(data.customer_id)
      setLicenseId(data.license_id)
      setExpiredRefreshDate(data?.refresh_token_expires_in)
    } catch (error) {
      setLoading(false)
      const err = new Error(
        error?.response?.data?.result?.error_message
          ? error.response.data?.result.error_message
          : t('account.somethingWentWrong')
      )
      err.data = error?.response ? error.response : {}
      throw err
    }
  }

  const logout = async () => {
    deleteToken()
    setIsAuthenticated(false)
    deleteAdminRole()
    deleteAdminCustomerId()
    deleteCustomerDatasetsAccess()
    deleteLicenseId()
  }

  return (
    <AuthContext.Provider
      value={{
        isAuthenticated,
        user,
        role,
        loading,
        login,
        logout,
        userInfo,
        isRequestsBlocked,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}
