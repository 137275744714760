export const SETTINGS_FREEREACTIONS_INC = 'SETTINGS_FREEREACTIONS_INC'
export const SETTINGS_FREEREACTIONS_RESET = 'SETTINGS_FREEREACTIONS_RESET'
export const SETTINGS_FREESEARCH_INC = 'SETTINGS_FREESEARCH_INC'
export const SETTINGS_FREESEARCH_RESET = 'SETTINGS_FREESEARCH_RESET'
export const SETTINGS_MOLSIZE = 'SETTINGS_MOLSIZE'
export const SETTINGS_SIDEBAR_TOGGLE = 'SETTINGS_SIDEBAR_TOGGLE'
export const SETTINGS_FREEMOL_INC = 'SETTINGS_FREEMOL_INC'

export const SETTINGS_SET_BASKET_TYPE = 'SETTINGS_SET_BASKET_TYPE'
export const SETTINGS_SET_SHOW_PUBLIC_PDF = 'SETTINGS_SET_SHOW_PUBLIC_PDF'
export const SETTINGS_LANG_CHANGE = 'SETTINGS_LANG_CHANGE'
export const SETTINGS_SHOW_TIPS_TOGGLE = 'SETTINGS_SHOW_TIPS_TOGGLE'

export const SETTINGS_SET_SESSION_INTERRUPTED =
  'SETTINGS_SET_SESSION_INTERRUPTED'
