import {
  UNCOMPARE_STRUCTURE_SUCCESS,
  GET_STRUCTURES_CALCULATION_INIT,
  ADD_STRUCTURE_TO_COMPARE_SUCCESS,
  GET_STRUCTURES_CALCULATION_SUCCESS,
  GET_STRUCTURES_COMPARISON_LIST_INIT,
  GET_STRUCTURES_COMPARISON_LIST_SUCCESS,
  CLEAR_STRUCTURES_COMPARISON_LIST_SUCCESS,
  ADD_BLUR_NON_APPLICABLE_PROPERTY,
} from 'store/constants/structuresCompare'

const initialState = {
  isStructuresLoading: false,
  isCalculationLoading: false,
  structures: [],
  blurNonApplicableProperties: [],
  calculation: [],
}

const structuresCompareReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case GET_STRUCTURES_COMPARISON_LIST_INIT:
      return {
        ...state,
        isStructuresLoading: true,
        structures: [],
      }

    case GET_STRUCTURES_COMPARISON_LIST_SUCCESS:
      return {
        ...state,
        isStructuresLoading: false,
        structures: payload.structures,
      }

    case ADD_STRUCTURE_TO_COMPARE_SUCCESS: {
      return {
        ...state,
        structures: [
          ...state.structures,
          { id: payload.id, baseID: payload.baseID, smiles: payload.smiles },
        ],
      }
    }

    case UNCOMPARE_STRUCTURE_SUCCESS: {
      return {
        ...state,
        structures: state.structures.filter((el) => el.id !== payload.id),
        calculation: state.calculation.filter(
          (el) => el.data.baseID !== payload.baseID
        ),
        blurNonApplicableProperties: state.blurNonApplicableProperties.filter(
          (p) => p.baseID !== payload.baseID
        ),
      }
    }

    case CLEAR_STRUCTURES_COMPARISON_LIST_SUCCESS: {
      return {
        ...state,
        structures: [],
        blurNonApplicableProperties: [],
      }
    }

    case GET_STRUCTURES_CALCULATION_INIT: {
      return {
        ...state,
        blurNonApplicableProperties: [],
        isCalculationLoading: true,
        calculation: [],
      }
    }

    case GET_STRUCTURES_CALCULATION_SUCCESS: {
      return {
        ...state,
        isCalculationLoading: false,
        calculation: payload.calculation,
      }
    }

    case ADD_BLUR_NON_APPLICABLE_PROPERTY: {
      return {
        ...state,
        blurNonApplicableProperties:
          payload.value.length === 0
            ? []
            : [...state.blurNonApplicableProperties, payload.value],
      }
    }

    default:
      return state
  }
}

export default structuresCompareReducer
