export const GET_STRUCTURES_COMPARISON_LIST_INIT =
  'GET_STRUCTURES_COMPARISON_LIST_INIT'
export const GET_STRUCTURES_COMPARISON_LIST_SUCCESS =
  'GET_STRUCTURES_COMPARISON_LIST_SUCCESS'

export const ADD_STRUCTURE_TO_COMPARE_INIT = 'ADD_STRUCTURE_TO_COMPARE_INIT'
export const ADD_STRUCTURE_TO_COMPARE_SUCCESS =
  'ADD_STRUCTURE_TO_COMPARE_SUCCESS'

export const UNCOMPARE_STRUCTURE_INIT = 'UNCOMPARE_STRUCTURE_INIT'
export const UNCOMPARE_STRUCTURE_SUCCESS = 'UNCOMPARE_STRUCTURE_SUCCESS'

export const CLEAR_STRUCTURES_COMPARISON_LIST_INIT =
  'CLEAR_STRUCTURES_COMPARISON_LIST_INIT'
export const CLEAR_STRUCTURES_COMPARISON_LIST_SUCCESS =
  'CLEAR_STRUCTURES_COMPARISON_LIST_SUCCESS'

export const GET_STRUCTURES_CALCULATION_INIT = 'GET_STRUCTURES_CALCULATION_INIT'
export const GET_STRUCTURES_CALCULATION_SUCCESS =
  'GET_STRUCTURES_CALCULATION_SUCCESS'

export const ADD_BLUR_NON_APPLICABLE_PROPERTY =
  'ADD_BLUR_NON_APPLICABLE_PROPERTY'
