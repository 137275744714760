import { useTheme } from 'styled-components'
import { useTranslation } from 'react-i18next'
import { Fragment, useEffect, useMemo, useRef, useState } from 'react'

import Icon from 'components/Icon'
import CustomScrollbar from 'components/CustomScrollbar'
import CustomInput from 'components/common/customInput'

import { Label } from 'components/common/text/index.style'
import RadioCheckbox from 'components/common/customRadioCheckbox'
import { OutboundClick } from 'components/common/outboundClick/outboundClick'

import {
  ArrowDownSvg,
  Count,
  Divider,
  IconWrapper,
  InputWithDivider,
  SelectContainer,
  SelectedValue,
  SelectedValues,
  SelectItem,
  SelectList,
  TagText,
} from './index.style'

const MultiSelectWithGroups = ({
  selectedValue,
  onChange,
  options,
  disabled,
  disabledOpening = false,
  width,
  withTranslation = true,
  allSelectedLabel = '',
  withWhiteBackground = false,
  emptyLabel,
  id = '',
  withGroups = false,
  withSearch = false,
}) => {
  const theme = useTheme()
  const listRef = useRef(null)

  const { t } = useTranslation()

  const [isOpen, setIsOpen] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const [openedGroups, setOpenedGroups] = useState([])
  const [filteredOptions, setFilteredOptions] = useState([])

  const allOptionsLength = useMemo(
    () =>
      withGroups
        ? options?.reduce((a, b) => a + b?.options?.length, 0)
        : options?.length,
    [options, withGroups]
  )

  const isAllSelected =
    selectedValue?.length === allOptionsLength && allOptionsLength !== 0

  const selectedValueToUse = useMemo(
    () => selectedValue.map((item) => item.value),
    [selectedValue]
  )

  useEffect(() => {
    const newOptions = inputValue
      ? options
          .map((el) => {
            const newOptions = el.options.filter((el) =>
              el.value.toLowerCase().includes(inputValue.toLowerCase())
            )
            return { ...el, options: newOptions }
          })
          .filter((el) => el.options?.length)
      : options
    setFilteredOptions(newOptions)

    if (inputValue)
      newOptions?.forEach((element) => {
        if (!openedGroups.includes(element.group))
          setOpenedGroups((prev) => [...prev, element.group])
      })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [options?.length, inputValue, withSearch])

  const toggleGroupOpen = (group) => {
    openedGroups.includes(group)
      ? setOpenedGroups((prev) => prev.filter((el) => el !== group))
      : setOpenedGroups((prev) => [...prev, group])
  }

  const getSelectedValueLabel = () => {
    if (isAllSelected) return allSelectedLabel
    if (!selectedValue?.length) return emptyLabel
    if (selectedValue?.length === 1)
      return withTranslation
        ? t(selectedValue[0]?.label)
        : selectedValue[0]?.label

    return (
      <SelectedValues>
        {selectedValue.slice(0, 2).map((el, i) => (
          <SelectedValue
            key={i}
            onClick={(e) => selectOption(e, el)}
            width={selectedValue?.length > 2 ? '4.5rem' : '7.5rem'}
            withWhiteBackground={withWhiteBackground}
          >
            <TagText>{withTranslation ? t(el.label) : el.label}</TagText>
            <Icon iconType="close" size="1rem" />
          </SelectedValue>
        ))}
        {selectedValue?.length > 2 && (
          <Count width="5rem" withWhiteBackground={withWhiteBackground}>
            <TagText>{`+${selectedValue?.length - 2}`}</TagText>
          </Count>
        )}
      </SelectedValues>
    )
  }

  const selectOption = (e, option) => {
    e.stopPropagation()

    if (selectedValue?.some((el) => el.value === option.value)) {
      onChange(selectedValue.filter((o) => o.value !== option.value))
    } else {
      onChange([...selectedValue, option])
    }
  }

  const selectGroup = (e, options, isGroupSelected) => {
    e.stopPropagation()
    if (isGroupSelected)
      onChange(
        selectedValue.filter(
          (o) => !options?.some((el) => o.value === el.value)
        )
      )
    else {
      onChange(Array.from(new Set([...selectedValue, ...options])))
    }
  }

  return (
    <OutboundClick onClick={() => setIsOpen(false)} className="select-outbound">
      <SelectContainer
        isOpen={isOpen}
        paddingLeft={
          selectedValue?.length > 1 && !isAllSelected ? '0.25rem' : '0.75rem'
        }
        withWhiteBackground={withWhiteBackground}
        onClick={(e) => {
          if (e?.target?.classList?.value.includes('crollbarsCustom')) return
          !disabled && !disabledOpening && setIsOpen((prev) => !prev)
        }}
        tabIndex={0}
        disabled={disabled}
        disabledOpening={disabledOpening}
        width={width}
        id={id}
      >
        <Label
          color={
            selectedValue?.length
              ? theme.colors.text.primary
              : theme.colors.text.tertiary
          }
        >
          {getSelectedValueLabel()}
        </Label>

        {isOpen && !disabledOpening && (
          <SelectList>
            <CustomScrollbar
              onClick={(e) => e?.stopPropagation()}
              fixedHeight={false}
              maxHeight={allOptionsLength < 5 ? 13.5 : 26.6875}
              trigger={filteredOptions?.length}
            >
              <div className="custom-scroll-wrapper" ref={listRef}>
                {withSearch && (
                  <InputWithDivider>
                    <CustomInput
                      value={inputValue}
                      onChange={setInputValue}
                      withClearButton
                      withWhiteBackground
                      placeholder={t('dataset_table.search')}
                      className="custom-select-search"
                    />

                    <Divider />
                  </InputWithDivider>
                )}

                {!inputValue && (
                  <Fragment key="select-all-categories-item">
                    <SelectItem
                      onClick={() =>
                        isAllSelected
                          ? onChange([])
                          : onChange(
                              options.reduce(
                                (acc, el) => [
                                  ...acc,
                                  ...(withGroups ? el.options : [el]),
                                ],
                                []
                              )
                            )
                      }
                    >
                      <RadioCheckbox
                        checkbox
                        value={isAllSelected}
                        type="white"
                        handler={() => {}}
                        id="select-all-categories-checkbox"
                        label={allSelectedLabel}
                        withWrap
                      />
                    </SelectItem>
                    <Divider />
                  </Fragment>
                )}

                {withGroups ? (
                  filteredOptions?.some((el) => el.options?.length) ? (
                    filteredOptions.map(({ group, options }, index, arr) => {
                      const isGroupOpen =
                        group === 'no_group' || openedGroups.includes(group)
                      const isGroupSelected = options.every((el) =>
                        selectedValueToUse.includes(el.value)
                      )
                      return (
                        <Fragment key={`select-group-${group}`}>
                          {group !== 'no_group' && (
                            <SelectItem
                              onClick={(e) =>
                                selectGroup(e, options, isGroupSelected)
                              }
                            >
                              <RadioCheckbox
                                checkbox
                                value={options.every((el) =>
                                  selectedValueToUse.includes(el.value)
                                )}
                                type="white"
                                label={t(group)}
                                handler={() => {}}
                                withWrap
                                id={`select-group-${group}-checkbox`}
                              />

                              <IconWrapper
                                isOpen={isGroupOpen}
                                onClick={(e) => {
                                  e.stopPropagation()
                                  toggleGroupOpen(group)
                                }}
                                isGroupBtn
                              >
                                <ArrowDownSvg
                                  iconType="arrowDown"
                                  open={isGroupOpen}
                                  size="1rem"
                                />
                              </IconWrapper>
                            </SelectItem>
                          )}

                          {isGroupOpen &&
                            options.map((option, index, optArr) => (
                              <Fragment key={`select-option-${option?.value}`}>
                                <SelectItem
                                  onClick={(e) => {
                                    selectOption(e, option)
                                  }}
                                >
                                  <RadioCheckbox
                                    handler={() => {}}
                                    checkbox
                                    value={selectedValue?.some(
                                      (el) => el.value === option.value
                                    )}
                                    type="white"
                                    label={
                                      withTranslation
                                        ? t(option.label)
                                        : option.label
                                    }
                                    withWrap
                                    id={`select-option-${option?.value}-checkbox`}
                                  />
                                </SelectItem>
                                {group === 'no_group' &&
                                  (index === optArr?.length - 1 &&
                                  arr?.length === 1 ? (
                                    <></>
                                  ) : (
                                    <Divider />
                                  ))}
                              </Fragment>
                            ))}

                          {group !== 'no_group' &&
                            index !== arr?.length - 1 && <Divider />}
                        </Fragment>
                      )
                    })
                  ) : (
                    <div style={{ padding: '0.5rem' }}>
                      {t('dataset_table.nothing_found')}
                    </div>
                  )
                ) : (
                  options.map((option) => (
                    <Fragment key={option?.value}>
                      <SelectItem
                        onClick={(e) => {
                          selectOption(e, option)
                        }}
                      >
                        <RadioCheckbox
                          checkbox
                          value={selectedValue?.some(
                            (el) => el.value === option.value
                          )}
                          type="white"
                          label={
                            withTranslation ? t(option.label) : option.label
                          }
                          handler={() => {}}
                          withWrap
                          id={`select-option-${option?.value}-checkbox`}
                        />
                      </SelectItem>
                    </Fragment>
                  ))
                )}
              </div>
            </CustomScrollbar>
          </SelectList>
        )}
        <IconWrapper isOpen={isOpen} withWhiteBackground={withWhiteBackground}>
          <ArrowDownSvg iconType="arrowDown" open={isOpen} size="1rem" />
        </IconWrapper>
      </SelectContainer>
    </OutboundClick>
  )
}

export default MultiSelectWithGroups
