export const SMILES_DRAWER_OPTIONS = {
  experimentalSSSR: true,
  overlapSensitivity: -1,
  themes: {
    lightHighlighted: {
      C: '#42C1BF',
      O: '#e74c3c',
      N: '#3498db',
      F: '#27ae60',
      CL: '#16a085',
      BR: '#d35400',
      I: '#8e44ad',
      P: '#d35400',
      S: '#f1c40f',
      B: '#e67e22',
      SI: '#e67e22',
      H: '#666',
      BACKGROUND: '#fff',
    },
    light: {
      C: '#222',
      O: '#e74c3c',
      N: '#3498db',
      F: '#27ae60',
      CL: '#16a085',
      BR: '#d35400',
      I: '#8e44ad',
      P: '#d35400',
      S: '#f1c40f',
      B: '#e67e22',
      SI: '#e67e22',
      H: '#666',
      BACKGROUND: '#fff',
    },
  },
}

export const TIPS_DELAY = 24 * 60 * 60 * 1000

export const ACCESS_RIGHTS_ERROR =
  'You must be a company admin to perform this action.'
