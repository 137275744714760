import { OutboundClick } from '../outboundClick/outboundClick'
import {
  Backdrop,
  CustomDialogHeader,
  Dialog,
  StyledButton,
} from './index.style'
import { CustomCloseIcon } from '../customCloseIcon'
import ReactDom from 'react-dom'
import Icon from 'components/Icon'
import { useTheme } from 'styled-components'

const CustomDialog = ({
  onClose,
  outboundClickEnabled = true,
  closeByEsc = true,
  width,
  height,
  maxHeight,
  padding,
  children,
  outboundExceptions,
  exceptionalParentIds,
  gap,
  hidden,
  overflowY,
  showCloseIcon = true,
  position = 'absolute',
  minWidth,
  outBoundId,
  pos = 'relative',
  right = 'auto',
  left = 'auto',
  top = 'auto',
  bottom = 'auto',
  withPortal = false,
  isPropagate,
  withBackdrop = true,
  onBodyClick,
  zIndex,
  align,
  id = 'custom-dialog',
}) => {
  const theme = useTheme()
  return withPortal ? (
    ReactDom.createPortal(
      <Backdrop
        hidden={hidden}
        position={position}
        withBackdrop={withBackdrop}
        zIndex={zIndex}
      >
        <OutboundClick
          outboundClickEnabled={outboundClickEnabled}
          onClick={onClose}
          onEsc={closeByEsc}
          exceptions={outboundExceptions}
          exceptionalParentIds={exceptionalParentIds}
          outBoundId={outBoundId}
          isPropagate={isPropagate}
        >
          <Dialog
            data-test="custom-dialog-modal"
            width={width}
            maxHeight={maxHeight}
            padding={padding}
            gap={gap}
            height={height}
            overflowY={overflowY}
            minWidth={minWidth}
            pos={pos}
            onClick={onBodyClick}
            align={align}
            id={id}
          >
            {showCloseIcon && (
              <CustomCloseIcon
                className="custom-close-icon"
                onClick={onClose}
              />
            )}
            {children}
          </Dialog>
        </OutboundClick>
      </Backdrop>,
      document.body
    )
  ) : (
    <Backdrop hidden={hidden} position={position} withBackdrop={withBackdrop}>
      <OutboundClick
        outboundClickEnabled={outboundClickEnabled}
        onClick={onClose}
        onEsc={closeByEsc}
        exceptions={outboundExceptions}
        exceptionalParentIds={exceptionalParentIds}
        outBoundId={outBoundId}
      >
        <Dialog
          data-test="custom-dialog-modal"
          width={width}
          maxHeight={maxHeight}
          padding={padding}
          gap={gap}
          height={height}
          overflowY={overflowY}
          minWidth={minWidth}
          pos={pos}
          right={right}
          left={left}
          top={top}
          bottom={bottom}
          id={id}
        >
          {showCloseIcon && (
            <StyledButton
              size="big"
              onClick={onClose}
              type="icon"
              defaultColor={theme.colors.icons.tertiary}
            >
              <Icon iconType={'close'} size="1.25rem" data-test="close-icon" />
            </StyledButton>
          )}
          {children}
        </Dialog>
      </OutboundClick>
    </Backdrop>
  )
}

export { CustomDialogHeader, CustomDialog }
