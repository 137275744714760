import { connect } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { memo, useEffect, useState } from 'react'

import { useAuth } from 'utils/auth/auth'
import { useTheme } from 'styled-components'
import { resetMe, toggleUserProfile } from 'store/actions/auth'
import CustomButton from 'components/common/customButton'
import {
  BottomBlock,
  IconWrapper,
  JobInfo,
  SectionDetails,
  SectionItem,
  Sections,
  UserInfo,
  UserInfoField,
  Wrapper,
} from './index.style'
import Icon from 'components/Icon'
import CustomTag from 'components/common/customTag'
import {
  Caption,
  Label,
  TitleSecondary,
} from 'components/common/text/index.style'
import { getLicenseData, SECTIONS_CONFIG } from '../config/config'
import { formatUtcShortDate } from 'utils/dateTime/formatUtcDate'
import SidebarButton from 'components/SidebarButton'
import { readStorage } from 'utils/storage/storage'

const MainSection = memo(
  ({ resetMe, userdata, toggleUserProfile, setSection }) => {
    const { t } = useTranslation()
    const theme = useTheme()
    const { logout } = useAuth()

    const [licenseData, setLicenseData] = useState({})

    const { name, surname, position, company, email } = userdata

    const handleLogout = () => {
      toggleUserProfile(false)
      logout()
      resetMe()
    }

    const jobInfo = [
      {
        icon: 'briefcase',
        text: company,
      },
      {
        icon: 'profile',
        text: position,
      },
    ]

    useEffect(() => {
      const licenseId = readStorage('license_id')
      getLicenseData(licenseId).then((data) => setLicenseData(data))
    }, [])

    return (
      <Wrapper>
        <UserInfo>
          <IconWrapper>
            <Icon iconType={'account'} size={'2.25rem'} />
          </IconWrapper>
          <CustomTag
            type={licenseData.is_free_trial ? 'attention' : 'accent_secondary'}
            height={'1.5rem'}
            isPointer={false}
            isBordered={true}
          >
            {t(
              licenseData.is_free_trial
                ? 'user_account.trial_period'
                : 'user_account.license'
            )}
            {licenseData?.expired_at &&
              ` ${t('user_account.up_to')} ${formatUtcShortDate(
                new Date(licenseData.expired_at)
              )}`}
          </CustomTag>
          <TitleSecondary
            ellipsis
            style={{ margin: '0.5rem 0 0.5rem', maxWidth: '100%' }}
          >
            {surname ? `${name} ${surname}` : name}
          </TitleSecondary>
          <UserInfoField maxWidth={'100%'}>
            <Icon size={'1rem'} iconType={'email'} />
            <Caption
              color={theme.colors.text.secondary}
              fontWeight={theme.fontWeight.medium}
              lineHeight={theme.text[14]}
              ellipsis
            >
              {email}
            </Caption>
          </UserInfoField>
          <JobInfo>
            {jobInfo.map(({ icon, text }) => (
              <UserInfoField key={icon} maxWidth={'50%'}>
                <Icon
                  size={'1rem'}
                  iconType={icon}
                  style={{ minWidth: '1rem' }}
                />
                <Caption
                  color={theme.colors.text.secondary}
                  fontWeight={theme.fontWeight.medium}
                  lineHeight={theme.text[14]}
                  ellipsis
                >
                  {text}
                </Caption>
              </UserInfoField>
            ))}
          </JobInfo>
        </UserInfo>
        <Sections>
          {SECTIONS_CONFIG.map(({ name, details }) => (
            <SectionItem key={name} onClick={() => setSection(name)}>
              <SectionDetails>
                <Label>{t(`user_account.${name}`)}</Label>
                <Caption
                  color={theme.colors.text.secondary}
                  lineHeight={theme.text[14]}
                >
                  {t(details)}
                </Caption>
              </SectionDetails>
              <Icon iconType={'arrowRight'} size={'1.25rem'} />
            </SectionItem>
          ))}
        </Sections>
        <BottomBlock>
          <SidebarButton type={'sidebarLanguage'} />
          <CustomButton type={'text'} gap={'0.25rem'} onClick={handleLogout}>
            {t('user_account.logout')}
            <Icon iconType={'logout'} size={'1rem'} />
          </CustomButton>
        </BottomBlock>
      </Wrapper>
    )
  }
)

MainSection.displayName = 'MainSection'

const mapStateToProps = (state) => {
  return {
    userdata: state.auth.userdata,
  }
}

const mapDispatchToProps = {
  resetMe,
  toggleUserProfile,
}

export default connect(mapStateToProps, mapDispatchToProps)(MainSection)
