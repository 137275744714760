import {
  LOAD_APPLICABILITY_INIT,
  LOAD_APPLICABILITY_SUCCESS,
  LOAD_CATEGORIES_ERROR,
  LOAD_CATEGORIES_INIT,
  LOAD_CATEGORIES_SUCCESS,
  LOAD_MOLECULE_PROPS_ERROR,
  LOAD_MOLECULE_PROPS_INIT,
  LOAD_MOLECULE_PROPS_SUCCESS,
  LOAD_RU_IUPAC_INIT,
  LOAD_RU_IUPAC_SUCCESS,
  LOAD_RU_IUPAC_ERROR,
  LOAD_SERVICES_ERROR,
  LOAD_SERVICES_INIT,
  LOAD_SERVICES_SUCCESS,
  RESET_RU_IUPAC,
  TOGGLE_APPLICABILITY,
  UPDATE_MOLECULE_PROPS_FINISHED,
  UPDATE_MOLECULE_PROPS_INIT,
  UPDATE_MOLECULE_PROPS_SUCCESS,
  UPDATE_MOLECULE_PROPS_ERROR,
  OPEN_ADD_MOLECULE_DIALOG,
  CLOSE_ADD_MOLECULE_DIALOG,
  RESET_MOLECULE_PROPS,
  SET_EDITOR_INDIVIDUAL_ID,
  SET_BLUR_NON_APPLICABLE,
  UPDATE_MOLECULE_NOTE_CONFIG,
  SET_MOLECULE_NOTE_DIALOG_ID,
  LOAD_PERSONAL_PROPERTIES_ERROR,
  LOAD_PERSONAL_PROPERTIES_INIT,
  LOAD_PERSONAL_PROPERTIES_SUCCESS,
  UPDATE_PERSONAL_PROPERTIES_ERROR,
  UPDATE_PERSONAL_PROPERTIES_INIT,
  UPDATE_PERSONAL_PROPERTIES_SUCCESS,
  TOGGLE_PERSONAL_PROPERTIES_WINDOW,
  RESET_NOTE_CONFIG,
} from 'store/constants/molecule'

export const initialState = {
  categories: {
    data: [],
    loading: false,
    error: false,
    blurNonApplicableProperties: [],
  },
  personal_properties: {
    data: [],
    loading: false,
    error: false,
    show_properties_window: false,
  },
  services: {
    data: [],
    loading: false,
    error: false,
  },
  properties: {
    mainId: null,
    basketId: null,
    data: null,
    sources: null,
    loading: false,
    error: false,
    isNeedHidePred: false,
  },
  ruIupac: '',
  applicability: {
    show: true,
    error: false,
    loading: false,
    data: null,
  },
  isAddMoleculeDialogOpen: false,
  addMoleculeDialogNodeId: null,
  editorIndividualId: null,
  moleculeNoteDialogId: null,
  noteConfig: {
    note: null,
    alias: null,
  },
}

const moleculeReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case LOAD_CATEGORIES_INIT:
      return {
        ...state,
        categories: {
          data: [],
          loading: true,
          error: false,
          blurNonApplicableProperties: [],
        },
      }

    case LOAD_CATEGORIES_SUCCESS:
      return {
        ...state,
        categories: {
          data: payload.data,
          loading: false,
          error: false,
          blurNonApplicableProperties: [],
        },
      }

    case LOAD_CATEGORIES_ERROR:
      return {
        ...state,
        categories: {
          data: [],
          loading: false,
          error: true,
          blurNonApplicableProperties: [],
        },
      }

    case LOAD_SERVICES_INIT:
      return {
        ...state,
        services: {
          data: [],
          loading: true,
          error: false,
        },
      }

    case LOAD_SERVICES_SUCCESS:
      return {
        ...state,
        services: {
          data: payload.data,
          loading: false,
          error: false,
        },
      }

    case LOAD_SERVICES_ERROR:
      return {
        ...state,
        services: {
          data: [],
          loading: false,
          error: true,
        },
      }

    case LOAD_MOLECULE_PROPS_INIT:
      return {
        ...state,
        properties: {
          mainId: null,
          basketId: null,
          data: null,
          sources: null,
          loading: true,
          error: false,
        },
      }

    case RESET_MOLECULE_PROPS:
      return {
        ...state,
        properties: initialState.properties,
      }

    case LOAD_MOLECULE_PROPS_SUCCESS:
      return {
        ...state,
        properties: {
          mainId: payload.mainId || null,
          basketId: payload.basketId || null,
          data: payload.data,
          sources: {},
          loading: payload.loading,
          error: false,
          isNeedHidePred: payload.isNeedHidePred,
        },
      }

    case LOAD_MOLECULE_PROPS_ERROR:
      return {
        ...state,
        properties: {
          loading: false,
          error: true,
        },
      }

    case UPDATE_MOLECULE_PROPS_INIT:
      return {
        ...state,
        properties: {
          ...state.properties,
          sources: null,
          loading: true,
        },
      }

    case UPDATE_MOLECULE_PROPS_SUCCESS: {
      const { data, sources } = payload

      return {
        ...state,
        properties: {
          ...state.properties,
          data:
            data && Object.keys(data).length
              ? {
                  ...state.properties.data,
                  ...data,
                }
              : state.properties.data,

          sources:
            sources && Object.keys(sources).length
              ? {
                  ...state.properties.sources,
                  ...sources,
                }
              : state.properties.sources,
        },
      }
    }
    case UPDATE_MOLECULE_PROPS_ERROR: {
      const { data, sources } = payload

      return {
        ...state,
        properties: {
          ...state.properties,
          data:
            data && Object.keys(data).length
              ? {
                  ...state.properties.data,
                  ...data,
                }
              : state.properties.data,

          sources:
            sources && Object.keys(sources).length
              ? {
                  ...state.properties.sources,
                  ...sources,
                }
              : state.properties.sources,
        },
      }
    }

    case UPDATE_MOLECULE_PROPS_FINISHED:
      return {
        ...state,
        properties: {
          ...state.properties,
          loading: false,
        },
      }

    case LOAD_RU_IUPAC_INIT:
      return {
        ...state,
        ruIupac: '',
      }

    case LOAD_RU_IUPAC_SUCCESS:
      return { ...state, ruIupac: payload.data }
    case LOAD_RU_IUPAC_ERROR:
      return { ...state, ruIupac: 'Невозможно создать корректное имя IUPAC' }
    case RESET_RU_IUPAC:
      return { ...state, ruIupac: '' }
    case TOGGLE_APPLICABILITY:
      return {
        ...state,
        applicability: {
          ...state.applicability,
          show: !state.applicability.show,
        },
      }
    case LOAD_APPLICABILITY_INIT:
      return {
        ...state,
        applicability: {
          ...state.applicability,
          data: null,
          loading: true,
          error: false,
        },
      }

    case LOAD_APPLICABILITY_SUCCESS:
      return {
        ...state,
        applicability: {
          ...state.applicability,
          data: payload.data,
          loading: false,
          error: false,
        },
      }
    case OPEN_ADD_MOLECULE_DIALOG:
      return {
        ...state,
        isAddMoleculeDialogOpen: payload.id,
        addMoleculeDialogNodeId: payload?.nodeId || null
      }

    case CLOSE_ADD_MOLECULE_DIALOG:
      return {
        ...state,
        isAddMoleculeDialogOpen: false,
        addMoleculeDialogNodeId: null
      }
    case SET_EDITOR_INDIVIDUAL_ID:
      return {
        ...state,
        editorIndividualId: payload.id,
      }
    case SET_BLUR_NON_APPLICABLE:
      return {
        ...state,
        categories: {
          ...state.categories,
          blurNonApplicableProperties:
            payload.value.length === 0
              ? []
              : [
                  ...state.categories.blurNonApplicableProperties,
                  payload.value,
                ],
        },
      }
    case SET_MOLECULE_NOTE_DIALOG_ID:
      return {
        ...state,
        moleculeNoteDialogId: payload.value,
      }

    case UPDATE_MOLECULE_NOTE_CONFIG:
      return {
        ...state,
        noteConfig: payload.config,
      }
    case RESET_NOTE_CONFIG:
      return {
        ...state,
        noteConfig: initialState.noteConfig,
      }

    case LOAD_PERSONAL_PROPERTIES_INIT:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          data: [],
          loading: true,
          error: false,
        },
      }

    case LOAD_PERSONAL_PROPERTIES_SUCCESS:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          data: payload.data,
          loading: false,
          error: false,
        },
      }

    case LOAD_PERSONAL_PROPERTIES_ERROR:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          data: [],
          loading: false,
          error: true,
        },
      }
    case UPDATE_PERSONAL_PROPERTIES_INIT:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          loading: true,
          error: false,
        },
      }

    case UPDATE_PERSONAL_PROPERTIES_SUCCESS:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          data: payload.data,
          loading: false,
          error: false,
        },
      }

    case UPDATE_PERSONAL_PROPERTIES_ERROR:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          loading: false,
          error: true,
        },
      }
    case TOGGLE_PERSONAL_PROPERTIES_WINDOW:
      return {
        ...state,
        personal_properties: {
          ...state.personal_properties,
          show_properties_window: payload.value,
        },
      }
    default:
      return state
  }
}

export default moleculeReducer
