import Scrollbars from 'react-scrollbars-custom'
import React, { useEffect, useRef, useState } from 'react'

const CustomScrollbar = React.memo((props) => {
  const {
    scrollbarEnabled,
    addWrapper,
    innerRef,
    noScrollX,
    noScrollY,
    translateContentSizeYToHolder,
    onScroll,
    trigger,
    fixedHeight = true,
    children,
    width = '100%',
    height = '100%',
    addedHeight,
    maxHeight,
    disableTrackYWidthCompensation,
    disableTrackXWidthCompensation,
    ...scrollbarProps
  } = props || {}

  const [childrenHeight, setChildrenHeight] = useState(0)
  const contentRef = useRef(null)

  useEffect(() => {
    if (!fixedHeight && contentRef?.current) {
      const currentHeight = contentRef.current.offsetHeight
      // maxheight in rem, current height in px
      setChildrenHeight(
        currentHeight > maxHeight * 16 ? maxHeight * 16 : currentHeight
      )
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [height, maxHeight, fixedHeight, contentRef, trigger])

  if (!props.scrollbarEnabled) {
    return props.className && addWrapper ? (
      <div className={props.className}>{props.children}</div>
    ) : (
      <>{props.children}</>
    )
  }

  return (
    <Scrollbars
      data-test="custom-scrollbar"
      translateContentSizeYToHolder={translateContentSizeYToHolder}
      noScrollX={noScrollX}
      noScrollY={noScrollY}
      {...scrollbarProps}
      className={`custom-scrollbar ${scrollbarProps.className || ''}`}
      ref={innerRef}
      removeTracksWhenNotUsed={true}
      onScroll={onScroll}
      disableTrackYWidthCompensation={disableTrackYWidthCompensation}
      disableTrackXWidthCompensation={disableTrackXWidthCompensation}
      style={{
        height: fixedHeight
          ? height
          : addedHeight
          ? childrenHeight + addedHeight
          : childrenHeight,
        width,
      }}
    >
      {addWrapper || !fixedHeight ? (
        <div ref={contentRef}>{children}</div>
      ) : (
        <>{children}</>
      )}
    </Scrollbars>
  )
})

CustomScrollbar.defaultProps = {
  scrollbarEnabled: true,
  noScrollX: true,
  noScrollY: false,
  translateContentSizeYToHolder: false,
}

CustomScrollbar.displayName = 'CustomScrollbar'
export default CustomScrollbar
