export const parseCSVString = (csvString) => {
  const rows = csvString.trim().split('\n')
  const headers = rows?.[0]?.split(',')?.map((header) => header.trim())

  const parsedData = []

  const parseRow = (row) => {
    const values = []
    let value = ''
    let inQuotes = false

    for (const char of row) {
      if (char === '"') {
        inQuotes = !inQuotes
      } else if (char === ',' && !inQuotes) {
        values.push(value.trim())
        value = ''
      } else {
        value += char
      }
    }
    values.push(value.trim())

    return values
  }

  for (let i = 1; i < rows.length; i++) {
    const rowData = {}
    const values = parseRow(rows[i])

    for (let j = 0; j < headers.length; j++) {
      rowData[headers[j]] = values[j] || ''
    }

    parsedData.push(rowData)
  }

  return parsedData
}
