import { Tooltip as ReactTooltip } from 'react-tooltip'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import React, { useEffect, useRef, useMemo, useState } from 'react'

import {
  Container,
  Thumbnail,
  SuccessText,
  DialogActions,
  AdditionalText,
  ThumbnailsBlock,
  DropzoneWrapper,
  ThumbnailContainer,
  FeedbackSection,
  Modules,
  SourceLinkWrapper,
  AgreementBlock,
  AgreementBlockItem,
  FilesScrollbarWrapper,
  MyDropZoneContainer,
  ExperimentalInfoText,
  ExperimentalInfoTextIcon,
  FeedbackFilewHeader,
  FileInfo,
  ThumbnailPDF,
} from './index.style'
import MyDropzone from './myDropzone'

import {
  Caption,
  Subheadline,
  TextLarge,
  TitleSecondary,
} from 'components/common/text/index.style'
import Icon from 'components/Icon'
import { css, useTheme } from 'styled-components'
import CustomInput from 'components/common/customInput'
import CustomButton from 'components/common/customButton'
import CustomTextArea from 'components/common/customTextArea'
import { emailReg } from 'utils/common/common'
import CustomScrollbar from 'components/CustomScrollbar'
import { SelectWithLabel } from 'components/MoleculeAddViewRestyled/index.style'
import CustomSelect from 'components/CustomSelect'
import { modules, themeOptions } from './config'
import CustomTag from 'components/common/customTag'
import CustomToggle from 'components/common/customToggle'
import RadioCheckbox from 'components/common/customRadioCheckbox'
import { useFeedbackValidation } from 'hooks/useFeedbackValidation'
import ContactFields from './ContactFields'
import SmartCaptchaComp from 'components/SmartCaptcha'
import LoaderRestyling from 'components/LoaderRestyling'
import UploadedFile from 'components/UploadedFile'

const FeedbackForm = ({ handleClose }) => {
  /**INIT - START */
  const theme = useTheme()
  const { t } = useTranslation()

  const mail = useSelector((store) => store.auth?.userdata?.email)
  const userName = useSelector((store) => store.auth?.userdata?.name)
  const userSurname = useSelector((store) => store.auth?.userdata?.surname)

  const nameRefs = useRef([])
  const [isScrollLeft, setIsScrollLeft] = useState(true)
  const [isScrollRight, setIsScrollRight] = useState(false)

  const defaultTheme = useSelector((store) => store.feedback.defaultTheme)

  const {
    state,
    files,
    rawFiles,
    errors,
    isSubmitting,
    loading,
    handleChange,
    setFiles,
    setRawFiles,
    handleSubmit,
    emailWarning,
    handleValidateCaptcha,
  } = useFeedbackValidation()
  const {
    name,
    surname,
    text,
    contact,
    showSuccessMsg,
    selectedTheme,
    selectedModules,
    haveOuterLink,
    outerLinkValue,
    citeAgreement,
    companyNameAgreement,
    showCaptcha,
  } = state || {}

  const isLiteratureTheme = selectedTheme.id === 5
  const isOnlyPDF = selectedTheme.id === 4 || isLiteratureTheme
  const isExperimentalDataTheme = selectedTheme.id === 4
  const shouldHideModules = selectedTheme.id === 1 || isLiteratureTheme || !mail

  const isPDFInputDisabled = isOnlyPDF && files.length >= 1

  const allowedModules = useMemo(() => {
    if (shouldHideModules) return []
    if (isExperimentalDataTheme)
      return modules.filter((module) => {
        return [1, 2, 7].includes(module.id)
      })
    return modules
  }, [shouldHideModules, isExperimentalDataTheme])
  /**INIT - END */

  /**METHODS - START */
  // const validateEmail = () => {
  //   return emailReg.test(contact)
  // }
  const handleSendFeedback = async (e) => {
    // const valid = validateEmail()
    // if (!validateEmail()) {
    //   handleChange('isEmailError', true)
    //   return
    // }
    handleSubmit(e)
  }

  const handleDeleteFile = (id) => {
    const targetFile = files?.[id]
    const updatedFiles = files.filter((_, idx) => idx !== id)
    const updatedRawFiles = rawFiles.filter((file) =>
      targetFile?.name ? file.name !== targetFile.name : file.name
    )
    setFiles(updatedFiles)
    setRawFiles(updatedRawFiles)
  }

  const handleToggleModule = (module) => {
    const { id } = module
    const isSelected = selectedModules?.some((i) => i === id)
    const updatedModules = isSelected
      ? selectedModules.filter((i) => i !== id)
      : [...selectedModules, id]
    handleChange('selectedModules', updatedModules)
  }
  const handleChangeTheme = (option) => {
    if (
      ((option.id === 4 || option.id === 5) &&
        files?.[0]?.data?.includes('image')) ||
      (option.id !== 4 && option.id !== 5 && files?.[0]?.data?.includes('pdf'))
    ) {
      setFiles([])
      setRawFiles([])
    }
    handleChange('selectedTheme', option)
  }

  const handleScrollFiles = (e) => {
    const { clientWidth, contentScrollWidth, scrollLeft } = e || {}
    setIsScrollLeft(scrollLeft === 0)
    setIsScrollRight(scrollLeft + clientWidth === contentScrollWidth)
  }
  /**METHODS - END */

  /**EFFECTS - START */

  useEffect(() => {
    shouldHideModules && handleChange('selectedModules', [])
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldHideModules])

  // filling out a contact for a logged-in user
  useEffect(() => {
    if (mail && mail !== contact) {
      handleChange('contact', mail)
    }
  }, [mail, contact, handleChange])

  // filling out the name and surname for a logged-in user
  useEffect(() => {
    if (userName && userName !== name) {
      handleChange('authName', [userName, userSurname])
    }
  }, [userName, name, handleChange])

  useEffect(() => {
    !haveOuterLink && handleChange('outerLinkValue', '')
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [haveOuterLink])

  useEffect(() => {
    if (defaultTheme) handleChangeTheme(defaultTheme)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  /**EFFECTS - END */

  return (
    <>
      {showSuccessMsg || showCaptcha ? (
        <Container
          alignItems="center"
          height="100%"
          justify="center"
          gap="1.5rem"
        >
          {showSuccessMsg ? (
            <>
              <Icon iconType="success" size="5rem" />

              <SuccessText>
                <TitleSecondary
                  textAlign="center"
                  color={theme.colors.text.primary}
                >
                  {t(
                    isOnlyPDF
                      ? 'feedback.data_received'
                      : 'feedback.message_received'
                  )}
                </TitleSecondary>
                <AdditionalText
                  fontWeight={theme.fontWeight.light}
                  textAlign="center"
                  color={theme.colors.text.secondary}
                >
                  {t(
                    isOnlyPDF
                      ? 'feedback.we_will_answer_data'
                      : 'feedback.we_will_answer'
                  )}
                </AdditionalText>
              </SuccessText>
              <CustomButton
                type="accent"
                width="100%"
                height="2.75rem"
                onClick={handleClose}
              >
                <TextLarge
                  fontWeight={theme.fontWeight.medium}
                  color={theme.colors.text.white}
                >
                  {' '}
                  {t('feedback.ok')}
                </TextLarge>
              </CustomButton>
            </>
          ) : loading ? (
            <LoaderRestyling />
          ) : (
            <SmartCaptchaComp
              onCompleteCaptcha={handleValidateCaptcha}
              scale={0.9}
            />
          )}
        </Container>
      ) : (
        <>
          <CustomScrollbar
            disableTrackXWidthCompensation={true}
            className={
              isExperimentalDataTheme && !!files?.length ? 'scroller--20' : ''
            }
          >
            <Container>
              <Caption
                fontWeight={400}
                color={theme.colors.text.tertiary}
                extraStyles={css`
                  margin-top: 0.25rem;
                `}
                lineHeight={theme.text[14]}
              >
                {t('feedback.required_field_tip')}
              </Caption>
              <FeedbackSection id="feedback-theme-section">
                <SelectWithLabel>
                  <Subheadline
                    color={theme.colors.text.primary}
                    fontWeight={theme.fontWeight.semibold}
                    extraStyles={css`
                      display: flex;
                      column-gap: 0.25rem;
                    `}
                  >
                    {t('feedback.subject_matter')}
                    {!!mail && (
                      <Caption
                        fontWeight={theme.fontWeight.medium}
                        color={theme.colors.text.tertiary}
                      >
                        *
                      </Caption>
                    )}
                  </Subheadline>
                  <CustomSelect
                    selectedValue={state.selectedTheme}
                    onChange={(option) => handleChangeTheme(option)}
                    width="100%"
                    options={themeOptions}
                    id="add-molecule-select"
                    padding="0.25rem"
                    disabled={!mail}
                    hideIcon={!mail}
                  />
                </SelectWithLabel>
                {isExperimentalDataTheme && (
                  <ExperimentalInfoText>
                    <Subheadline
                      color={theme.colors.text.secondary}
                      fontWeight={400}
                      fontSize="11px"
                    >
                      {t('feedback.experimental_data_text')}
                    </Subheadline>
                    <ExperimentalInfoTextIcon>
                      <Icon iconType="info" size="1.25rem" />
                    </ExperimentalInfoTextIcon>
                  </ExperimentalInfoText>
                )}
              </FeedbackSection>
              {!!allowedModules.length && (
                <FeedbackSection id="feedback-modules-section">
                  <Subheadline
                    color={theme.colors.text.primary}
                    fontWeight={theme.fontWeight.semibold}
                    extraStyles={css`
                      display: flex;
                      column-gap: 0.25rem;
                    `}
                  >
                    {t('feedback.choose_module')}
                    <Caption
                      fontWeight={theme.fontWeight.medium}
                      color={theme.colors.text.tertiary}
                    >
                      *
                    </Caption>

                    <Icon
                      iconType="info"
                      size="1rem"
                      data-tooltip-id="modules-hint-tooltip"
                      className={errors.modules ? 'feedback-error-icon' : ''}
                    />

                    <ReactTooltip
                      id="modules-hint-tooltip"
                      className="c-tooltip c-tooltip-base"
                      classNameArrow="c-tooltip-arrow"
                      delayHide={100}
                      place={'top'}
                    >
                      {t('feedback.select_multiple_modules')}
                    </ReactTooltip>
                  </Subheadline>

                  <Modules>
                    {allowedModules?.map((module) => (
                      <CustomTag
                        type={
                          selectedModules?.some((i) => i === module.value)
                            ? 'accent'
                            : 'secondary_grey'
                        }
                        key={module.id}
                        onClick={() => handleToggleModule(module)}
                        padding="0.625rem 0.375rem"
                        radius="0.5rem"
                        fontSize="0.75rem"
                      >
                        {t(module.label)}
                      </CustomTag>
                    ))}
                  </Modules>
                </FeedbackSection>
              )}

              {/* additional fields for unauthorized user */}
              {!mail && (
                <ContactFields
                  {...{
                    errors,
                    name,
                    surname,
                    contact,
                    isSubmitting,
                    emailWarning,
                  }}
                  onChange={handleChange}
                />
              )}

              <FeedbackSection id="feedback-message-section">
                {mail || selectedTheme.id !== 2 ? (
                  <Subheadline
                    color={theme.colors.text.primary}
                    fontWeight={theme.fontWeight.semibold}
                    extraStyles={css`
                      display: flex;
                      column-gap: 0.25rem;
                    `}
                  >
                    {t('feedback.comment')}
                    <Caption
                      fontWeight={theme.fontWeight.medium}
                      color={theme.colors.text.tertiary}
                    >
                      *
                    </Caption>
                  </Subheadline>
                ) : (
                  <Caption
                    fontWeight={theme.fontWeight.medium}
                    color={theme.colors.text.secondary}
                  >
                    {t('feedback.your_problem')}
                  </Caption>
                )}
                <CustomTextArea
                  value={text}
                  onChange={(value) => handleChange('text', value)}
                  placeholder={
                    mail || selectedTheme.id !== 2
                      ? t('feedback.your_message')
                      : t('feedback.required_field')
                  }
                  withClearButton
                  initHeight="7.5rem"
                  className={`feedback-item_error-${
                    isSubmitting && !!errors.text
                  }`}
                />
              </FeedbackSection>
              <FeedbackSection id="feedback-files-section">
                <FeedbackFilewHeader isOnlyPDF={isOnlyPDF}>
                  {isOnlyPDF ? (
                    <>
                      <Subheadline
                        color={theme.colors.text.primary}
                        fontWeight={theme.fontWeight.semibold}
                        extraStyles={css`
                          display: flex;
                          column-gap: 0.25rem;
                        `}
                      >
                        {t('feedback.file')}
                        <Caption
                          fontWeight={theme.fontWeight.medium}
                          color={theme.colors.text.tertiary}
                        >
                          *
                        </Caption>
                        {errors.file_or_link && (
                          <Icon
                            iconType="info"
                            className="feedback-error-icon"
                          />
                        )}
                      </Subheadline>

                      <Subheadline
                        fontWeight={theme.fontWeight.light}
                        color={theme.colors.text.secondary}
                      >
                        {t('feedback.provide_file_or_link')}
                      </Subheadline>
                    </>
                  ) : (
                    <Subheadline
                      color={theme.colors.text.primary}
                      fontWeight={theme.fontWeight.semibold}
                      extraStyles={css`
                        display: flex;
                        column-gap: 0.25rem;
                      `}
                    >
                      {t('feedback.image')}
                    </Subheadline>
                  )}

                  <DropzoneWrapper>
                    <MyDropZoneContainer
                      id="dropzone-container"
                      isPDFInputDisabled={isPDFInputDisabled}
                    >
                      <MyDropzone
                        setRawFiles={setRawFiles}
                        setFiles={setFiles}
                        files={files}
                        format={isOnlyPDF ? 'pdf' : 'image'}
                        addBtnText={
                          isOnlyPDF
                            ? 'feedback.attach_file'
                            : 'feedback.attach_image'
                        }
                        isPDFInputDisabled={isPDFInputDisabled}
                      />
                    </MyDropZoneContainer>
                  </DropzoneWrapper>
                </FeedbackFilewHeader>
                {!!files.length &&
                  (isOnlyPDF ? (
                    <UploadedFile
                      name={files[0]?.name}
                      type={files[0]?.type}
                      size={files[0]?.size}
                      onDelete={() => handleDeleteFile(0)}
                    />
                  ) : (
                    <FilesScrollbarWrapper
                      isOnlyPDF={isOnlyPDF}
                      isScrollLeft={isScrollLeft}
                      isScrollRight={isScrollRight}
                    >
                      <CustomScrollbar
                        noScrollY={true}
                        noScrollX={false}
                        height={isOnlyPDF ? '8rem' : '10rem'}
                        disableTrackXWidthCompensation={true}
                        onScroll={handleScrollFiles}
                      >
                        <ThumbnailsBlock>
                          {files?.map(({ name, data }, idx) => {
                            const isPDF = data
                              ?.toString()
                              ?.includes('application/pdf')
                            return (
                              <React.Fragment key={idx}>
                                <ThumbnailContainer>
                                  {isPDF ? (
                                    <ThumbnailPDF id="pdf-file-container">
                                      <Icon
                                        iconType="pdf"
                                        id="pdf-remove-icon"
                                        dataTest="feedback-attachment-remove-btn"
                                      />
                                    </ThumbnailPDF>
                                  ) : (
                                    <Thumbnail src={data} alt="Thumbnail" />
                                  )}

                                  <FileInfo>
                                    <Subheadline
                                      data-test="feedback-attachment-name-div"
                                      id={`thumbnail-${idx}`}
                                      data-tooltip-id={`file-info-name${idx}`}
                                      ref={(ref) =>
                                        (nameRefs.current[idx] = ref)
                                      }
                                    >
                                      {name}
                                    </Subheadline>
                                    <Icon
                                      id="image-delete-icon"
                                      iconType="deleted"
                                      size="1rem"
                                      onClick={() => handleDeleteFile(idx)}
                                      dataTest="feedback-attachment-remove-btn"
                                    />
                                  </FileInfo>
                                </ThumbnailContainer>

                                {nameRefs?.current?.[idx]?.scrollWidth >
                                  nameRefs?.current?.[idx]?.clientWidth && (
                                  <ReactTooltip
                                    id={`file-info-name${idx}`}
                                    className="c-tooltip c-tooltip-base c-tooltip-filename"
                                    place={'bottom'}
                                    offset={14}
                                    classNameArrow="c-tooltip-arrow"
                                    positionStrategy="fixed"
                                  >
                                    {name}
                                  </ReactTooltip>
                                )}
                              </React.Fragment>
                            )
                          })}
                        </ThumbnailsBlock>
                      </CustomScrollbar>
                    </FilesScrollbarWrapper>
                  ))}

                {isOnlyPDF && (
                  <>
                    <SourceLinkWrapper>
                      <Subheadline color={theme.colors.text.secondary}>
                        {t('feedback.got_link')}
                      </Subheadline>
                      <CustomToggle
                        type="grey"
                        value={haveOuterLink}
                        onClick={() => {
                          handleChange('haveOuterLink', !haveOuterLink)
                        }}
                      />
                    </SourceLinkWrapper>
                    {haveOuterLink && (
                      <CustomInput
                        onChange={(value) =>
                          handleChange('outerLinkValue', value)
                        }
                        value={outerLinkValue}
                        placeholder={t('feedback.enter_link')}
                        withClearButton
                        className={`feedback-item_error-${
                          isSubmitting && !!errors.file_or_link
                        }`}
                      />
                    )}

                    {/* {!!files.length && ( */}
                    <AgreementBlock>
                      {!isExperimentalDataTheme && (
                        <AgreementBlockItem>
                          <RadioCheckbox
                            checkbox
                            value={companyNameAgreement}
                            handler={() =>
                              handleChange(
                                'companyNameAgreement',
                                !companyNameAgreement
                              )
                            }
                            id="feedback-agreement-company-name-checkbox"
                            className={`feedback-checkbox_error-${
                              isSubmitting && !!errors.file_or_link
                            }`}
                            label={t('feedback.agreement_cite_terms')}
                            size="small"
                            withWrap
                          />
                        </AgreementBlockItem>
                      )}
                      <AgreementBlockItem align="top">
                        {' '}
                        <RadioCheckbox
                          checkbox
                          value={citeAgreement}
                          handler={() =>
                            handleChange('citeAgreement', !citeAgreement)
                          }
                          id="feedback-agreement-cite-checkbox"
                          className={`feedback-checkbox_error-${
                            isSubmitting && !!errors.cite_agreement
                          }`}
                          label={t('feedback.agreement_terms')}
                          size="small"
                          withWrap
                        />
                      </AgreementBlockItem>
                    </AgreementBlock>
                    {/* )} */}
                  </>
                )}
              </FeedbackSection>
            </Container>
          </CustomScrollbar>
          <DialogActions>
            <CustomButton onClick={handleClose} type="secondary" width="50%">
              {t('feedback.closeButton')}
            </CustomButton>
            <CustomButton
              width="50%"
              onClick={handleSendFeedback}
              type="accent"
              disabled={loading || emailWarning}
            >
              {t('feedback.sendButton')}
            </CustomButton>
          </DialogActions>
        </>
      )}
    </>
  )
}

export default FeedbackForm
