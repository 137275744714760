import styled from 'styled-components'
import { Caption } from '../text/index.style'

export const RangeContainer = styled.div`
  height: ${({ opened }) => (opened ? '2.25rem' : '0')};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened }) => (opened ? '2.25rem' : '0')};
  opacity: ${({ opened }) => (opened ? '1' : '0')};

  transition: all 0.3s;
`

export const DualRangeTitle = styled(Caption)`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

export const Range = styled.div`
  width: 90%;
  margin: auto;
`

export const RangeSlider = styled.div`
  height: 0.125rem;
  position: relative;
  border-radius: 0.125rem;
`

export const RangeSelected = styled.span`
  height: 100%;
  left: 30%;
  right: 30%;
  position: absolute;
  border-radius: 0.3125rem;
  background: ${({ disabled, theme }) =>
    disabled
      ? theme.colors.backgrounds.accentSecondary
      : theme.colors.backgrounds.accent};
`

export const RangeInputWrapper = styled.div`
  position: relative;
`
export const RangeInput = styled.input`
  position: absolute;
  width: 100%;
  height: 0.125rem;
  top: -0.125rem;
  background: none;
  pointer-events: none;
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;

  &:first-child {
    &::-webkit-slider-thumb {
      transform: translateX(-0.25rem);
    }

    &::-moz-range-thumb {
      transform: translateX(-0.25rem);
    }
  }

  &:disabled {
    &::-webkit-slider-thumb {
      background: ${({ theme }) => theme.colors.backgrounds.accentSecondary};
      cursor: not-allowed;
    }
  }

  &::-webkit-slider-thumb {
    cursor: pointer;
    height: 0.75rem;
    width: 0.75rem;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.backgrounds.accent};
    pointer-events: auto;
    -webkit-appearance: none;
  }

  &::-moz-range-thumb {
    cursor: pointer;
    height: 0.75rem;
    width: 0.75rem;
    border: none;
    border-radius: 50%;
    background: ${({ theme }) => theme.colors.backgrounds.accent};
    pointer-events: auto;
    -moz-appearance: none;
  }
`
export const RangeValues = styled.div`
  width: 100%;
  display: flex;
  gap: 0.5rem;
  justify-content: space-between;
  align-items: center;
  background: ${({ theme, variant }) =>
    variant === 'grey'
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};
  border-radius: 0.75rem;
  display: flex;
  justify-content: space-between;
  padding: 0.625rem 0.75rem;
  transition: all 0.3s;

  & > span {
    display: block;
    font-size: 0.75rem;
    font-weight: 400;
    line-height: 1rem;
    color: ${({ theme }) => theme.colors.text.tertiary};
    transition: all 0.3s;
  }
`

export const RangeValuePlaceholder = styled.div`
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  transition: all 0.3s;
`

export const RangeValuesInput = styled.input`
  width: 30%;
  padding: 0;
  border: none;
  outline: none;
  background: transparent;
  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  transition: all 0.3s;

  input[type='number']::-webkit-inner-spin-button,
  input[type='number']::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  &::placeholder {
    color: ${({ theme }) => theme.colors.text.tertiary};
  }
`

export const RangeValueBlock = styled.div`
  display: flex;
  align-items: center;
  gap: 0.1875rem;
  transition: all 0.3s;

  &:last-child {
    justify-content: end;
  }

  ${RangeValuesInput}, ${RangeValuePlaceholder} {
    color: ${({ theme, isChanged }) =>
      isChanged ? theme.colors.text.primary : theme.colors.text.tertiary};
  }
`

export const HideBtn = styled.button`
  margin-left: auto;
  border: none;
  outline: none;
  display: flex;

  width: 1.25rem;
  height: 1.25rem;
  padding: 0.25rem;
  align-items: center;
  justify-content: center;
  border-radius: 0.375rem;
  background: ${({ theme, variant }) =>
    variant === 'grey'
      ? theme.colors.buttons.secondary
      : theme.colors.buttons.primary};
  cursor: pointer;

  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
    transition: color 0.3s;
    transform: ${({ opened }) =>
      opened ? 'rotateX(180deg)' : 'rotateX(0deg)'};
  }
`

export const DualRangeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  transition: all 0.3s;

  &:hover {
    ${RangeValues} > span, ${RangeValuesInput}, ${RangeValuePlaceholder} {
      color: ${({ theme, disabled, isChanging }) => {
        if (isChanging) return theme.colors.text.accentPrimary
        return !disabled && theme.colors.text.primary
      }};
    }
  }
`
