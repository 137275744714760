import { createRoot } from 'react-dom/client'
import './styles/_fonts.css'
import './i18n'
import App from './components/App'
import './styles/ketcher.css'
import './styles/customScrollbar.css'
import './styles/agGrid.css'
import './styles/datePicker.css'

export const init = async () => {
  // eslint-disable-next-line no-undef
  SENTRY_ENABLE && (await import('./services/sentry'))

  const container = document.getElementById('root')
  const root = createRoot(container)
  root.render(<App />)
}

init()
