import styled from 'styled-components'

export const TextContent = styled.div`
  gap: 0.5rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  width: 100%;

  h3,
  p {
    white-space: pre-line;
  }
`
