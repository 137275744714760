import { useTranslation } from 'react-i18next'

import RadioCheckbox from 'components/common/customRadioCheckbox'

import { Checkboxes } from './index.style'

const RadioCheckboxes = ({ operator, onChangeOperator }) => {
  const { t } = useTranslation()
  return (
    <Checkboxes>
      <RadioCheckbox
        radio
        type="white"
        value={operator === 'AND'}
        label={t('dataset_table.and')}
        handler={() =>
          operator === 'AND' ? onChangeOperator(null) : onChangeOperator('AND')
        }
        id="table-filter-and-operator"
        size="small"
      />

      <RadioCheckbox
        radio
        type="white"
        value={operator === 'OR'}
        handler={() =>
          operator === 'OR' ? onChangeOperator(null) : onChangeOperator('OR')
        }
        id="table-filter-or-operator"
        label={t('dataset_table.or')}
        size="small"
      />
    </Checkboxes>
  )
}

export default RadioCheckboxes
