import { v4 as uuidv4 } from 'uuid'
import {
  LOAD_BASKETS_INIT,
  LOAD_BASKETS_FAIL,
  LOAD_BASKETS_SUCCESS,
  LOAD_LAST_CHANGE_INIT,
  LOAD_LAST_CHANGE_SUCCESS,
  LOAD_LAST_CHANGE_FAIL,
  LOAD_MOLECULES_INIT,
  LOAD_MOLECULES_FAIL,
  LOAD_MOLECULES_SUCCESS,
  DELETE_MOLECULE_INIT,
  DELETE_MOLECULE_SUCCESS,
  DELETE_MOLECULE_FAIL,
  CREATE_MOLECULE_INIT,
  CREATE_MOLECULE_SUCCESS,
  CREATE_MOLECULE_FAIL,
  UPDATE_MOLECULE_INIT,
  UPDATE_MOLECULE_FAIL,
  UPDATE_MOLECULE_SUCCESS,
  ADD_BASKET_INIT,
  DELETE_BASKET_INIT,
  DELETE_BASKET_SUCCESS,
  DELETE_BASKET_FAIL,
  COPY_BASKET_INIT,
  COPY_BASKET_SUCCESS,
  COPY_BASKET_FAIL,
  JOIN_BASKETS_INIT,
  JOIN_BASKETS_SUCCESS,
  JOIN_BASKETS_FAIL,
  ADD_BASKET_SUCCESS,
  ADD_BASKET_FAIL,
  UPDATE_BASKET_INIT,
  UPDATE_BASKET_FAIL,
  UPDATE_BASKET_SUCCESS,
  LOAD_MORE_MOLECULES_SUCCESS,
  LOAD_MORE_MOLECULES_FAIL,
  LOAD_MORE_MOLECULES_INIT,
  MOVE_MOLECULE_INIT,
  MOVE_MOLECULE_FAIL,
  MOVE_MOLECULE_SUCCESS,
  CREATE_MOLECULES_PACK_SUCCESS,
  CREATE_MOLECULES_PACK_FAIL,
  CREATE_MOLECULES_PACK_INIT,
  CALC_TLIGHT_INIT,
  CALC_TLIGHT_FAIL,
  CREATE_MOLECULES_FROM_FILE_INIT,
  SET_SEARCH_ERROR,
  SET_SEARCH_LOADING,
  SET_SEARCH_V2,
  SET_SEARCH_RESULT,
  SET_SEARCH_TRANSIT_LOADING,
  SET_SEARCH_TRANSIT_ERROR,
  SET_SEARCH_TRANSIT_DONE,
  SET_LIT_SEARCH_LOADING,
  SET_LIT_SEARCH_RESULT,
  SET_LIT_SEARCH_ERROR,
  SET_LIT_SEARCH,
  SET_BINGO_SEARCH_LOADING,
  SET_BINGO_SEARCH_PAGE_CACHE,
  SET_BINGO_SEARCH_ERROR,
  SET_BINGO_SEARCH,
  SET_BINGO_SEARCH_RESULT,
  SET_BINGO_SEARCH_TASK_ID,
  SET_LIT_SEARCH_TASK_ID,
  SET_LIT_SEARCH_STATE,
  SET_BINGO_SEARCH_IS_LONG_TASK,
  SHOW_SEARCH_V2_RESULT,
  SET_REACTIONS_SEARCH_LOADING,
  SET_REACTIONS_SEARCH_RESULT,
  SET_REACTIONS_SEARCH_ERROR,
  SET_REACTIONS_SEARCH,
} from '../constants/crud'
import { SET_PAGINATION_CONFIG } from '../constants/search'
import {
  ADD_NOTIFICATION,
  REMOVE_NOTIFICATION,
  UPDATE_NOTIFICATION,
} from '../constants/notifications'
import { fetch, store, update, storeV2 } from '../../services/rest'

import {
  put,
  takeLatest,
  takeEvery,
  takeLeading,
  select,
  delay,
} from 'redux-saga/effects'
import {
  SET_FILTER_BUTTONS_ACTIVE,
  SET_FILTER_BUTTONS_DISABLED,
  SET_MARKUSH_MOLECULE_MAX_WEIGHT,
} from 'store/constants/filter'
import { CONFLICT_OPTIONS } from 'components/Search/LiteratureFilter/constants'
import { SET_TEXT } from '../constants/search'
import {
  SET_LIT_FILTER_CONFIG,
  SET_PREV_SEARCH_DATA_ID,
  SET_LAST_SEARCHED_TEXT,
} from 'store/constants/literature'
import { t } from 'i18next'
import {
  checkMarkushFieldsError,
  convertLitSearchConfig,
  countAddedMolItems,
  getBasketsQueryParams,
  getMarkushNotification,
  getSmilesBySuccessType,
} from './utils/utils'
import {
  BASKETS_ENDPOINT_BY_TYPE,
  BINGO_PAGINATION_LIMIT,
  REACTIONS_PAGINATION_LIMIT,
} from './utils/config'
import { setPrevSearchDataId } from 'store/actions/literature'
import {
  CHECK_CUSTOMER_DATASETS_ACCESS,
  REMOVE_BASKET_IDS_FROM_DB_ERROR,
  REMOVE_BASKET_IDS_FROM_DB_INIT,
  REMOVE_BASKET_IDS_FROM_DB_SUCCESS,
  SET_SELECTED_BASKET,
  TRANSFER_TO_CUSTOMER_DATASETS,
} from 'store/constants/basket'
import { compareArrayValues } from 'pages/Baskets/helpers/helpers'
import { setStorage } from 'utils/storage/storage'
import { SET_REACTIONS_COMPOUND_ID } from 'store/constants/searchReactions'
import { SETTINGS_SET_BASKET_TYPE } from 'store/constants/settings'
import { ACCESS_RIGHTS_ERROR } from 'config/constants'

const MOVE_ERROR_MESSAGES = [
  'User is not source and target owner',
  'At least one of molecules already exists in target or does not exist in source',
]

function* loadBasket({ basketType, withSearch = true }) {
  try {
    const sortingConfig = yield select((state) => state.basket.sorting)
    const searchName = yield select((state) => state.basket.searchName)
    let resp
    const searchCondition = withSearch && searchName?.length >= 3

    if (searchCondition) {
      resp = yield fetch(
        `/search_baskets?${getBasketsQueryParams(
          sortingConfig,
          searchName,
          basketType
        )}`
      )
    } else {
      resp = yield fetch(
        `/baskets/${
          BASKETS_ENDPOINT_BY_TYPE[basketType]
        }?${getBasketsQueryParams(sortingConfig, '')}`
      )
    }
    yield put({
      type: LOAD_BASKETS_SUCCESS,
      data: resp.data,
      isBasketsAfterSearch: searchCondition,
    })
  } catch (err) {
    yield put({
      type: LOAD_BASKETS_FAIL,
      error: err,
    })
  }
}

function* loadLastChange() {
  try {
    const resp = yield fetch('/last-change')

    yield put({
      type: LOAD_LAST_CHANGE_SUCCESS,
      data: resp.data,
    })
  } catch (err) {
    yield put({
      type: LOAD_LAST_CHANGE_FAIL,
      error: err,
    })
  }
}

function* loadMolecules({ basket, search }) {
  try {
    const req = {
      legacySearch: search,
      withPredict: true,
    }
    const { data } = yield store(
      `/molecules/${isNaN(basket) ? 1 : basket}`,
      req
    )

    if (data) {
      const config = {
        total: data.count,
        pagesAmount: Math.ceil(data.count / 50),
        perPage: 50,
        activePage: 1,
      }

      yield put({
        type: SET_PAGINATION_CONFIG,
        config,
      })
    }

    yield put({
      type: LOAD_MOLECULES_SUCCESS,
      openedBasketID: isNaN(basket) ? 1 : basket,
      data: data.data,
      public: data.public,
      withSearch: data.withSearch,
      pending: data.pending,
    })
  } catch (err) {
    yield put({
      type: LOAD_MOLECULES_FAIL,
      error: err,
    })
  }
}

function* calcTLight({ ids, basket }) {
  try {
    yield storeV2('traffic_light/calculate', {
      molecules_ids: ids,
    })
    const notify = {
      id: uuidv4(),
      name: 'notification.calc_tlight_success',
      notification_type: 'success',
      autoRemove: true,
      timeout: 5000,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket,
    })
  } catch (e) {
    yield put({
      type: CALC_TLIGHT_FAIL,
      error: e,
    })
  }
}

function* loadMoreMolecules({ basket, limit, page }) {
  try {
    const req = { withPredict: true, limit, page }
    const { data } = yield store(`/molecules/${basket}`, req)

    yield put({
      type: LOAD_MORE_MOLECULES_SUCCESS,
      data: data.data,
    })
  } catch (err) {
    put({
      type: LOAD_MORE_MOLECULES_FAIL,
      error: err,
    })
  }
}

function* createMolecule({
  basket,
  smiles,
  shouldGoToBasket,
  label,
  t,
  isTargetEqualsSource,
  isSelected,
}) {
  const basketType = yield select((state) => state.settings.basketType)

  try {
    if (basket === -1) {
      let resp = yield store('/baskets', {
        name: label,
        desc: label,
      })
      basket = resp.data.id
    }

    const res = yield store(`/molecules_pack`, {
      basket_id: basket,
      list: smiles,
    })
    if (res.status === 200) {
      const { succeedCount, failedCount } = countAddedMolItems(res)
      const info = succeedCount.length
        ? 'notification.not_all_molecules_added_to_basket'
        : smiles.length > 1
        ? 'notification.molecules_not_added_to_basket'
        : 'notification.molecule_not_added_to_basket'
      const translationNameParams = {
        name: label || '',
      }

      if (failedCount.length) {
        const { invalidSmiles, repeatedSmiles } = getSmilesBySuccessType(res)

        if (repeatedSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            translationNameParams,
            text: 'Structure already exists in basket',
            notification_type: 'warning',
            autoRemove: false,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              type: 'createMolecule',
              smiles: repeatedSmiles,
              target: basket,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (invalidSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure is not valid',
            notification_type: 'warning',
            translationNameParams,
            autoRemove: false,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              type: 'createMolecule',
              smiles: invalidSmiles,
              target: basket,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (!succeedCount.length)
          yield put({
            type: CREATE_MOLECULE_FAIL,
          })
      }
      if (succeedCount.length) {
        yield put({
          type: CREATE_MOLECULE_SUCCESS,
        })

        if (shouldGoToBasket) {
          yield put({
            type: LOAD_MOLECULES_INIT,
            basket,
          })

          yield put({
            type: LOAD_BASKETS_INIT,
            withPublic: true,
            basketType,
          })
        }

        if (!failedCount.length) {
          const id = uuidv4()
          const oneMolText = isSelected
            ? 'notification.selected_molecule_add'
            : 'notification.molecule_add'

          const manyMolsText = isSelected
            ? 'notification.selected_molecules_add'
            : 'notification.molecules_add'

          const notify = {
            id,
            name: succeedCount.length > 1 ? manyMolsText : oneMolText,
            translationNameParams,
            notification_type: 'success',
            timeout: 5000,
            autoRemove: true,
            withActions: isTargetEqualsSource ? false : true,
            params: {
              target: basket,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
    }
  } catch (err) {
    yield put({
      type: CREATE_MOLECULE_FAIL,
    })

    const notify = {
      id: uuidv4(),
      name: 'notification.molecules_not_added_to_basket',
      text: 'Network error',
      translationNameParams: {
        name: label || '',
      },
      notification_type: 'error',
      withActions: true,
      autoRemove: false,
      params: {
        type: 'createMolecule',
        basket,
        smiles,
        shouldGoToBasket,
        label,
        t,
        isTargetEqualsSource,
        isSelected,
      },
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* createMoleculesFromFile({
  basket,
  file,
  smiles_target_col,
  delimiter,
}) {
  try {
    const headers = { 'Content-type': 'multipart/form-data' }

    const formData = new FormData()
    formData.append('file', file)
    formData.append('basket_id', basket)
    smiles_target_col && formData.append('csv_smiles_header', smiles_target_col)
    delimiter && formData.append('delimiter', delimiter)

    yield store('/uploadFileToBasket', formData, headers)
    yield put({
      type: CREATE_MOLECULE_SUCCESS,
    })

    const notify = {
      id: uuidv4(),
      name: 'notification.uploading_started',
      notification_type: 'success',
      autoRemove: true,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })

    let isBasketReady = false
    while (!isBasketReady) {
      const tasks = yield fetch(`/userTasksStatus`)
      yield delay(2000)
      if (
        tasks.data.some(
          (el) =>
            el?.basket_id === basket &&
            !(el?.status === 'initiated' || el?.status === 'running')
        )
      )
        isBasketReady = true
    }
    if (isBasketReady) {
      const openedBasket = yield select((state) => state.crud.openedBasketID)
      if (openedBasket === basket)
        yield put({
          type: LOAD_MOLECULES_INIT,
          basket,
        })
    }
  } catch (e) {
    yield put({
      type: CREATE_MOLECULES_PACK_FAIL,
      error: e,
    })
  }
}

function* createMoleculesPack({ basket, list, t, basket_name }) {
  try {
    const res = yield store(`/molecules_pack`, {
      basket_id: basket,
      list,
    })
    if (res.status === 200) {
      const { succeedCount, failedCount } = countAddedMolItems(res)
      const info = succeedCount.length
        ? 'notification.not_all_molecules_added_to_basket'
        : 'notification.molecules_not_added_to_basket'

      if (failedCount.length) {
        const { invalidSmiles, repeatedSmiles } = getSmilesBySuccessType(res)

        if (repeatedSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure already exists in basket',
            notification_type: 'warning',
            autoRemove: false,
            translationNameParams: {
              name: basket_name ?? '',
            },
            params: {
              type: 'createMolecule',
              smiles: repeatedSmiles,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (invalidSmiles.length) {
          const notify = {
            id: uuidv4(),
            name: `${info}`,
            text: 'Structure is not valid',
            notification_type: 'warning',
            autoRemove: false,
            params: {
              type: 'createMolecule',
              smiles: invalidSmiles,
            },
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }

        if (!succeedCount.length)
          yield put({
            type: CREATE_MOLECULE_FAIL,
          })
      }

      if (succeedCount.length) {
        yield put({
          type: CREATE_MOLECULES_PACK_SUCCESS,
        })
        yield put({
          type: LOAD_MOLECULES_INIT,
          basket,
        })
        if (!failedCount?.length) {
          const id = uuidv4()

          const notify = {
            id,
            name:
              succeedCount?.length > 1
                ? 'notification.molecules_add'
                : 'notification.molecule_add',

            translationNameParams: {
              name: basket_name ?? '',
            },
            notification_type: 'success',
            timeout: 5000,
            autoRemove: true,
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
    }
  } catch (err) {
    yield put({
      type: CREATE_MOLECULE_FAIL,
    })
    const notify = {
      id: uuidv4(),
      name: 'notification.molecules_not_added_to_basket',
      text: 'Network error',
      notification_type: 'error',
      withActions: true,
      autoRemove: false,
      translationNameParams: {
        name: basket_name ?? '',
      },
      params: {
        type: 'createMolecule',
        basket,
        list,
        t,
      },
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* deleteMolecule({ id, basket }) {
  const basketType = yield select((state) => state.settings.basketType)

  try {
    yield store(`/molecules/`, { basket_id: basket, id: [id] })
    yield put({
      type: DELETE_MOLECULE_SUCCESS,
    })
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket,
    })
    yield put({
      type: LOAD_BASKETS_INIT,
      withPublic: true,
      basketType,
    })
  } catch (err) {
    if (err?.response?.data?.result?.error_message === ACCESS_RIGHTS_ERROR) {
      const notify = {
        id: uuidv4(),
        name: 'notification.error',
        text: 'moleculeslist.topblock.you_must_be_admin',
        notification_type: 'error',
        autoRemove: true,
      }

      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
    yield put({
      type: DELETE_MOLECULE_FAIL,
      error: err,
    })
  }
}

function* updateMolecule({ id, basket, smiles }) {
  try {
    yield update(`/molecules/${id}`, { smiles, basket_id: basket })
    yield put({
      type: UPDATE_MOLECULE_SUCCESS,
    })
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket,
    })
  } catch (err) {
    yield put({
      type: UPDATE_MOLECULE_FAIL,
      error: err,
    })
  }
}

function* addBasket({ name, datasetType }) {
  try {
    const res = yield store('/baskets', {
      name,
      desc: ' ',
      ...(datasetType === 'companies' ? { by_customer: true } : {}),
    })

    const isSuccess = res?.status === 200
    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_add_success'
        : 'notification.dataset_add_error',
      notification_type: isSuccess ? 'success' : 'error',
      autoRemove: true,
    }
    yield put({ type: ADD_BASKET_SUCCESS })
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
    yield put({
      type: SETTINGS_SET_BASKET_TYPE,
      basketType: datasetType,
    })
  } catch (e) {
    console.log(e)
    yield put({ type: ADD_BASKET_FAIL })
  } finally {
    yield put({
      type: LOAD_BASKETS_INIT,
      withPublic: true,
      basketType: datasetType,
    })
  }
}

function* deleteBasket({ list }) {
  const basketType = yield select((state) => state.settings.basketType)

  try {
    const res = yield store(`/delete-baskets/`, { basket_ids: list })
    if (res && res?.status === 200) {
      yield put({ type: DELETE_BASKET_SUCCESS })
      const notify = {
        id: uuidv4(),
        name:
          list?.length > 1
            ? 'notification.datasets_delete_success'
            : 'notification.dataset_delete_success',
        notification_type: 'cancel',
        autoRemove: true,
        params: {
          actionType: 'baskets-delete',
          deleteIDs: list,
        },
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } catch (e) {
    console.log(e)
    yield put({ type: DELETE_BASKET_FAIL })
    if (e?.response?.data?.result?.error_message === ACCESS_RIGHTS_ERROR) {
      const notify = {
        id: uuidv4(),
        name: 'notification.error',
        text: 'baskets.topblock.you_must_be_admin',
        notification_type: 'error',
        autoRemove: true,
      }

      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true, basketType })
  }
}

function* removeBasketFromDB(data) {
  const ids = data?.ids || []
  const lastChange = yield fetch(`/last-change`)
  const lastChangeRemovedIDs = lastChange?.data?.[0]?.deleted_baskets || []

  if (Array.isArray(ids) && Array.isArray(lastChangeRemovedIDs)) {
    const isEqualIDs = compareArrayValues(ids, lastChangeRemovedIDs)
    if (isEqualIDs) {
      try {
        const res = yield store(`/completely-remove-baskets/`, {
          basket_ids: [...ids],
        })
        if (res.status === 200)
          yield put({ type: REMOVE_BASKET_IDS_FROM_DB_SUCCESS })
      } catch (e) {
        console.log(e)
        yield put({ type: REMOVE_BASKET_IDS_FROM_DB_ERROR })
      }
    }
  }
}

function* copyBasket(data) {
  if (!data) return
  const { basket_id, new_basket_name, new_basket_desc } = data?.data || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    const res = yield store(`/copy-basket/`, {
      baskets: [
        {
          basket_id,
          new_basket_name,
          new_basket_desc,
        },
      ],
    })
    const isSuccess = res?.status === 200

    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_copy_success'
        : 'notification.dataset_copy_error',
      notification_type: isSuccess ? 'success' : 'error',
      autoRemove: true,
    }
    yield put({ type: COPY_BASKET_SUCCESS })
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  } catch (e) {
    console.log(e)
    yield put({ type: COPY_BASKET_FAIL })
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true, basketType })
  }
}

function* joinBaskets(data) {
  if (!data) return
  const { basket_ids, new_basket_name, new_basket_desc } = data?.data || {}
  const basketType = yield select((state) => state.settings.basketType)

  try {
    const res = yield store('/baskets_join', {
      basket_ids,
      new_basket_name,
      new_basket_desc,
    })
    const isSuccess = res?.status === 200

    const notify = {
      id: uuidv4(),
      name: isSuccess
        ? 'notification.dataset_merge_success'
        : 'notification.dataset_merge_error',
      notification_type: isSuccess ? 'cancel' : 'error',
      autoRemove: true,
      params: {
        actionType: 'baskets-join',
        deleteIDs: basket_ids,
      },
    }
    yield put({ type: JOIN_BASKETS_SUCCESS })
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true, basketType })
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  } catch (e) {
    console.log(e)
    yield put({ type: JOIN_BASKETS_FAIL })

    const notify = {
      id: uuidv4(),
      name: 'notification.dataset_merge_error',
      notification_type: 'error',
      autoRemove: true,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* updateBasket({ id, name, desc }) {
  const basketType = yield select((state) => state.settings.basketType)

  try {
    yield update(`/baskets/${id}`, { name, desc })
    yield put({ type: UPDATE_BASKET_SUCCESS })
  } catch (e) {
    console.log(e)
    yield put({ type: UPDATE_BASKET_FAIL })
  } finally {
    yield put({ type: LOAD_BASKETS_INIT, withPublic: true, basketType })
  }
}

function* moveMolecules({
  target,
  source,
  molecules,
  label = 'Moved molecules',
  t,
}) {
  const basketType = yield select((state) => state.settings.basketType)

  try {
    if (target === -1) {
      let resp = yield store('/baskets', {
        name: label,
        desc: label,
      })
      target = resp.data.id
    }
    const res = yield store('/molecules_move', {
      target,
      source,
      molecules,
    })
    if (Object.prototype.hasOwnProperty.call(res?.data?.result, 'is_success')) {
      const { error_message, is_success } = res.data.result || {}
      const hasError = is_success === false && !!error_message
      const text =
        !!error_message && MOVE_ERROR_MESSAGES.includes(error_message)
          ? `notification.${error_message}`
          : 'notification.unknown_error'
      const id = uuidv4()
      let notify
      if (hasError) {
        notify = {
          id: uuidv4(),
          name: 'notification.molecules_move_error',
          text,
          notification_type: 'error',
          autoRemove: false,
        }
      } else {
        notify = {
          id,
          name:
            molecules.length > 1
              ? 'notification.selected_molecules_moved'
              : 'notification.selected_molecule_moved',
          translationNameParams: { name: label || '' },
          notification_type: 'success',
          timeout: 5000,
          autoRemove: true,
          withActions: target === source ? false : true,
          params: {
            target,
          },
        }
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
      if (!hasError) {
        yield put({
          type: MOVE_MOLECULE_SUCCESS,
        })
      } else {
        yield put({ type: MOVE_MOLECULE_FAIL })
      }
    }
  } catch (e) {
    yield put({ type: MOVE_MOLECULE_FAIL })
  } finally {
    yield delay(1000)
    yield put({
      type: LOAD_MOLECULES_INIT,
      basket: source,
    })
    yield put({
      type: LOAD_BASKETS_INIT,
      withPublic: true,
      basketType,
    })
  }
}
/*eslint-disable*/

export function* searchV2({ ids = [], smiles = '', text = '', basket = null }) {
  try {
    yield put({ type: SET_BINGO_SEARCH_TASK_ID, taskId: '' })
    yield put({ type: SET_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })
    const openedBasket = yield select((state) => state.crud.openedBasketID)
    const request = {
      searchV2: { ids, smiles, text },
      basket: openedBasket || basket || 1,
    }
    const { data } = yield storeV2('/molecule/molecules_search', request)
    const { exact_match, similar_results } = data.data

    yield put({
      type: SET_SEARCH_RESULT,
      result: data.data,
      public: data.public,
      total: data.total,
    })

    const config = yield select((state) => state.filter.config)
    if (config?.match_type === 'exact match' && !exact_match) {
      const id = uuidv4()
      const notify = {
        id,
        name: t(
          similar_results.length > 0
            ? 'notification.we_couldnt_found_best_match'
            : 'notification.we_couldnt_find_desired_structure'
        ),
        text: t(
          similar_results.length > 0
            ? 'notification.showed_you_similar_results'
            : 'notification.check_your_spelling_or_enter_SMILES'
        ),
        notification_type: 'warning',
        autoRemove: true,
        timeout: 5000,
      }

      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } catch (e) {
    console.log(e)
    yield put({ type: SET_SEARCH_ERROR })
  } finally {
    yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
  }
}

export function* showSearchV2Result({ taskUuid }) {
  try {
    yield put({ type: SET_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })

    let status = 'initiated'
    while (status === 'initiated') {
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=0&limit=100`
      )

      status = searchData.data.metadata.status

      if (status === 'ok') {
        yield put({
          type: SET_SEARCH_RESULT,
          result: searchData.data.result.data,
        })

        break
      }

      if (status === 'error') {
        yield put({
          type: SET_SEARCH_RESULT,
          result: [],
          public: true,
        })
        break
      }

      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put({
      type: SET_SEARCH_RESULT,
      result: [],
      public: true,
    })
  }
}

export function* bingoSearch({
  ids = [],
  smiles = '',
  text = '',
  task_uuid,
  offset = 0,
  limit = BINGO_PAGINATION_LIMIT,
  showPastResult = false,
}) {
  try {
    let taskUuid = task_uuid

    const config = yield select((state) => state.filter.config)
    const {
      similarity,
      match_type,
      molecular_weight,
      molecular_weight_default,
    } = config
    const isMarkush = match_type === 'markush structures'

    const weightDiff =
      Math.round(
        (Number(molecular_weight?.[1]) - Number(molecular_weight?.[0])) * 1000
      ) / 1000

    const isMarkushFieldsError =
      isMarkush &&
      checkMarkushFieldsError(
        molecular_weight,
        molecular_weight_default,
        weightDiff
      )

    yield put({ type: SET_BINGO_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })
    if (!taskUuid) {
      yield put({
        type: SET_BINGO_SEARCH_PAGE_CACHE,
        pageItems: [],
        pageNumber: 0,
      })
      yield put({ type: SET_BINGO_SEARCH_IS_LONG_TASK, isLongTask: true })

      const openedBasket = yield select((state) => state.crud.openedBasketID)
      const request = {
        searchV2: { ids, smiles, text },
        basket: openedBasket || 1,
      }
      let searchData

      try {
        const slicedString = text.slice(0, text.indexOf('|'))
        const isSubstructure = slicedString.split('').filter((el) => el === '*')

        if (
          isSubstructure.length > 2 ||
          (isMarkush && !text.includes('|')) ||
          isMarkushFieldsError
        ) {
          const { notificationName, translationNameParams } =
            getMarkushNotification(
              text,
              isSubstructure,
              isMarkush,
              molecular_weight,
              molecular_weight_default,
              weightDiff
            )
          const notify = {
            id: uuidv4(),
            name: notificationName,
            translationNameParams,
            notification_type: 'error',
            timeout: 5000,
            autoRemove: true,
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
          if (!showPastResult) yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
          yield put({ type: SET_BINGO_SEARCH_ERROR })
          yield put({ type: SET_SEARCH_TRANSIT_ERROR })
          return
        }
        yield put({ type: SET_SEARCH_TRANSIT_LOADING })

        if (!isSubstructure.length) {
          const { data } = yield storeV2('/molecule/molecules_search', request)
          searchData = data.data
        }

        yield put({ type: SET_SEARCH_TRANSIT_DONE })
      } catch (e) {
        console.log(e)
        yield put({ type: SET_SEARCH_TRANSIT_ERROR })
        yield put({ type: SET_BINGO_SEARCH_RESULT, result: [] })
      }

      const foundSmiles = searchData?.exact_match
        ? searchData.exact_match.smiles
        : searchData?.similar_results?.[0]?.smiles

      if (foundSmiles || isMarkush) {
        if (!isMarkush) yield put({ type: SET_TEXT, text: foundSmiles })
        let body = {
          smiles: foundSmiles,
          method: 'sub',
        }
        if (match_type === 'similar structures') {
          body = {
            smiles: foundSmiles,
            method: 'sim',
            metric: 'Tanimoto',
            bottom: Number(similarity[0]),
            top: Number(similarity[1]),
          }
        }
        if (isMarkush) {
          const getTop = () => {
            if (Number(molecular_weight?.[0]) && !molecular_weight?.[1])
              return Number(molecular_weight?.[0]) + 100
            if (molecular_weight?.[1]) return Number(molecular_weight[1])
            return 100
          }

          body = {
            smiles: text,
            method: 'markush',
            bottom:
              molecular_weight && molecular_weight[0]
                ? Number(molecular_weight[0])
                : 0,
            top: getTop(),
          }
          yield put({
            type: SET_MARKUSH_MOLECULE_MAX_WEIGHT,
            weight: body.top.toString(),
          })
        }
        const { data } = yield store(`/bingo_search`, body)
        taskUuid = data.task_uuid
      } else {
        let body = {
          smiles: request.searchV2.text,
          method: 'sub',
        }
        const { data } = yield store(`/bingo_search`, body)
        taskUuid = data.task_uuid
      }

      yield put({
        type: SET_BINGO_SEARCH_TASK_ID,
        taskId: taskUuid,
      })
    }

    let status = 'initiated'
    while (status === 'initiated') {
      const bingoStatus = yield select((state) => state.crud.bingoSearch.status)
      if (bingoStatus === 'done') {
        yield put({ type: SET_BINGO_SEARCH_IS_LONG_TASK, isLongTask: false })
        if (!showPastResult) yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
        break
      }
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=${offset}&limit=${limit}`
      )

      status = searchData.data.metadata.status

      if (status === 'ok') {
        yield put({
          type: SET_BINGO_SEARCH_RESULT,
          result: searchData.data.result.data,
          total: searchData.data.result.total,
        })
        if (!task_uuid) {
          yield put({
            type: SET_BINGO_SEARCH_IS_LONG_TASK,
            isLongTask: false,
          })

          const searchHistoryData = yield fetch(
            `/search_history?offset=0&limit=1`
          )
          if (searchHistoryData) {
            const config = {
              total: searchHistoryData.data[0].result_count,
              pagesAmount: Math.ceil(
                searchHistoryData.data[0].result_count / 50
              ),
              perPage: 50,
              activePage: 1,
            }

            yield put({
              type: SET_PAGINATION_CONFIG,
              config,
            })
          }
        }
        const pastResult = yield select(
          (state) => state.searchHistory.pastResult
        )
        if (!showPastResult && !pastResult)
          yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
      }
      if (status === 'error') {
        if (showPastResult)
          yield put({
            type: SET_BINGO_SEARCH_RESULT,
            result: [],
          })
        else {
          yield put({ type: SET_BINGO_SEARCH_ERROR })
          yield put({ type: SET_SEARCH_TRANSIT_ERROR })
          yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
          const notify = {
            id: uuidv4(),
            name: 'notification.we_couldnt_find_desired_structure',
            text: 'notification.check_your_spelling_or_enter_SMILES',
            notification_type: 'warning',
            autoRemove: true,
            timeout: 5000,
          }
          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
        }
      }
      if (status === 'initiated' && showPastResult) {
        yield put({
          type: SET_BINGO_SEARCH_RESULT,
          result: [],
        })
        break
      }

      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put({ type: SET_BINGO_SEARCH_ERROR })
    yield put({ type: SET_SEARCH_TRANSIT_ERROR })
    yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
    const notify = {
      id: uuidv4(),
      name: 'notification.we_couldnt_find_desired_structure',
      text: 'notification.check_your_spelling_or_enter_SMILES',
      notification_type: 'warning',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* literatureSearch({
  task_uuid = '',
  offset = 0,
  limit = 20,
  showPastResult = false,
}) {
  try {
    const pastResult = yield select((state) => state.searchHistory.pastResult)
    if (showPastResult) yield put({ type: SET_FILTER_BUTTONS_DISABLED })
    else if (!pastResult) {
      put({ type: SET_FILTER_BUTTONS_ACTIVE })
    }

    let taskUuid = task_uuid
    yield put({ type: SET_LIT_SEARCH_LOADING })

    const idForNotification = uuidv4()

    if (!taskUuid) {
      const notify = {
        id: uuidv4(),
        name: 'notification.literature_search_started',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
      const text = yield select((state) => state.search.searchText)
      const lastSearchedText = yield select(
        (state) => state.literature.lastSearchedText
      )
      const syntellyId = yield select((state) => state.literature.syntellyId)
      const rightFilterConfig = yield select(
        (state) => state.literature.filter.config
      )
      const leftFilterConfig = yield select((state) => state.filter.config)

      const sortingConfig = yield select((state) => state.search.sorting)
      // get searchDataId. If get obj with smiles and id -  we use structures_id . If get null - we use title and abstract. If we have rightFilterConfig.name - skip `/get_structure_by_default_view` step
      let searchDataId = syntellyId || null

      try {
        if (!rightFilterConfig?.name && !!text.trim().length && !syntellyId) {
          yield put({ type: SET_SEARCH_TRANSIT_LOADING })
          const reqText =
            rightFilterConfig?.name && lastSearchedText === text ? '' : text
          const { data } = yield store(`/get_structure_by_default_view`, {
            text: reqText,
          })
          if (data?.id) searchDataId = data.id
          yield put({ type: SET_SEARCH_TRANSIT_DONE })
        }
      } catch (e) {
        console.log(e)
        yield put({ type: SET_SEARCH_TRANSIT_ERROR })
      }

      // get filtered type for searching in conflict conditions
      let filteredType = {
        type: !leftFilterConfig?.document_type?.length
          ? ['patent', 'article']
          : [...leftFilterConfig.document_type],
      }
      // get ( structuresId ) or ( title and abstract if molecules_search not found synId && if first request )
      let structuresId = {}
      const prevsearchDataId = yield select(
        (state) => state.literature.prevSearchDataId
      )

      if (searchDataId) {
        const { title, abstract, ...rest } = rightFilterConfig || {}
        yield put({
          type: SET_LIT_FILTER_CONFIG,
          config: prevsearchDataId === null ? { ...rest } : rightFilterConfig,
        })

        structuresId = {
          structures_ids: {
            operator: 'should',
            exact: true,
            values: [searchDataId],
          },
        }
      }
      // clear prev filter config if new text given  //если в абстракт или тайтл введено не подменять
      let filteredTitleAbstract = {}
      if (searchDataId === null && !rightFilterConfig?.name) {
        const getUpdatedData = () => {
          const newObj = {
            title: {
              operator: rightFilterConfig?.title?.operator ?? 'should',
              exact: false,
              values:
                prevsearchDataId !== null
                  ? [text]
                  : [
                      ...(rightFilterConfig?.title?.values &&
                      lastSearchedText === text
                        ? [...rightFilterConfig.title.values]
                        : [text]),
                    ],
            },
            abstract: {
              operator: rightFilterConfig?.abstract?.operator ?? 'should',
              exact: false,
              values:
                prevsearchDataId !== null
                  ? [text]
                  : [
                      ...(rightFilterConfig?.abstract?.values &&
                      lastSearchedText === text
                        ? [...rightFilterConfig.abstract.values]
                        : [text]),
                    ],
            },
          }

          return {
            ...(newObj.title.values?.[0]?.length
              ? { title: newObj?.title }
              : {}),
            ...(newObj.abstract.values?.[0]?.length
              ? { abstract: newObj.abstract }
              : {}),
          }
        }
        const newObj = getUpdatedData()
        //delete structures_ids if searchData === null
        const { structures_ids, ...restConfig } = rightFilterConfig

        try {
          yield put({
            type: SET_LIT_FILTER_CONFIG,
            config: {
              ...restConfig,
              ...newObj,
            },
          })
          filteredTitleAbstract = { ...newObj }
        } catch (e) {
          console.log(e)
        }
      }
      yield put({
        type: SET_PREV_SEARCH_DATA_ID,
        prevSearchDataId: searchDataId,
      })
      setPrevSearchDataId(searchDataId)
      // left filter has priority over right one. If author in left filter - overwriting the right value with the left one
      const filteredAuthor = () => {
        if (leftFilterConfig?.author) {
          return {
            authors: {
              operator: 'should',
              exact: false,
              values: [leftFilterConfig.author],
            },
          }
        }
        return {}
      }

      // left filter has priority over right one. Left one filters out the right one
      const filteredLanguage = () => {
        const isRightFilterLanguage =
          rightFilterConfig?.language?.values?.length
        const isLeftFilterLanguage = leftFilterConfig?.language?.length

        if (!isRightFilterLanguage && isLeftFilterLanguage) {
          return {
            language: {
              operator:
                leftFilterConfig.language?.length > 1 ? 'should' : 'must',
              exact: true,
              values: [...leftFilterConfig.language],
            },
          }
        }
        if (isRightFilterLanguage && isLeftFilterLanguage) {
          return {
            language: {
              operator: rightFilterConfig.language.operator,
              exact: true,
              values: [
                ...rightFilterConfig.language.values.filter((value) =>
                  leftFilterConfig.language.includes(value)
                ),
              ],
            },
          }
        }
        if (isRightFilterLanguage && !isLeftFilterLanguage) {
          return {
            language: {
              operator:
                rightFilterConfig.language.values?.length > 1
                  ? rightFilterConfig.language.operator
                  : 'must',
              exact: true,
              values: [...rightFilterConfig.language.values],
            },
          }
        }
        return {}
      }

      // update right filter config after change when add structures id above
      const newRightFilterConfig = yield select(
        (state) => state.literature.filter.config
      )

      if (
        newRightFilterConfig?.published_date &&
        !Object.values(newRightFilterConfig.published_date).some((el) => el)
      ) {
        delete newRightFilterConfig.published_date
      }

      // only left filter
      const filteredPublishedDate = () => {
        const isLeftFilterDate = Object.values(
          leftFilterConfig?.published_date || []
        ).some((el) => el)

        if (!isLeftFilterDate) return {}

        const fromDate = leftFilterConfig?.published_date[0]
          ? {
              from_date: `${Number(leftFilterConfig?.published_date[0])}-01-01`,
            }
          : {}

        const toDate = leftFilterConfig?.published_date[1]
          ? {
              to_date: `${Number(leftFilterConfig?.published_date[1])}-12-31`,
            }
          : {}
        return { published_date: { ...fromDate, ...toDate } }
      }

      const sorting = () => {
        if (sortingConfig.type === 'relevance') return {}
        else
          return {
            sort: {
              key: sortingConfig.type,
              order_type: sortingConfig.direction,
            },
          }
      }
      const { title, abstract, ...rest } = newRightFilterConfig

      const keysToCheck = ['title', 'abstract'] //rm from final config keys with empty value
      for (const key of keysToCheck) {
        if (
          newRightFilterConfig.hasOwnProperty(key) &&
          newRightFilterConfig[key]['values'] &&
          newRightFilterConfig[key]['values']?.length === 1 &&
          newRightFilterConfig[key]['values'][0] === ''
        ) {
          delete newRightFilterConfig[key]
        }
      }
      const config = {
        ...newRightFilterConfig,
        ...filteredTitleAbstract,
        ...filteredType,
        ...structuresId,
        ...filteredAuthor(),
        ...filteredLanguage(),
        ...filteredPublishedDate(),
        ...sorting(),
      }

      // check conflcited types
      if (filteredType?.type?.length === 1) {
        const conflictCondition = CONFLICT_OPTIONS[filteredType.type[0]]
        const configValues = Object.keys(config)

        conflictCondition.map((condition) => {
          if (configValues.includes(condition)) {
            delete config[condition]
          }
        })
      }
      const shouldBlocks = yield select(
        (state) => state.literature.filter.shouldBlocksIndexes
      )
      const newConfig = convertLitSearchConfig(config, shouldBlocks)

      const { data } = yield store(`/advanced-text-search`, newConfig)
      if (lastSearchedText !== text) {
        yield put({
          type: SET_LAST_SEARCHED_TEXT,
          text,
        })
      }
      if (!data.task_uuid) throw new Error('error')
      taskUuid = data.task_uuid

      yield put({
        type: SET_LIT_SEARCH_TASK_ID,
        taskId: taskUuid,
      })

      const progress_notify = {
        id: idForNotification,
        name: 'notification.literature_search_progress',
        autoRemove: false,
        notification_type: 'progress',
        progress: 0,
        timeout: 5000,
        withIcon: false,
      }
      yield put({
        type: ADD_NOTIFICATION,
        task: progress_notify,
      })
    }
    let status = 'initiated'

    while (
      (status === 'initiated' || status === 'running') &&
      status !== 'error'
    ) {
      const paginationState = yield select((state) => state.search.pagination)
      const { activePage, perPage } = paginationState || {}
      const offsetToUse = activePage === 1 ? 0 : (activePage - 1) * perPage
      const searchData = yield fetch(
        `/search_history/${taskUuid}?offset=${offsetToUse}&limit=${limit}`
      )
      status = searchData.data.metadata.status
      const progress = searchData?.data?.metadata?.progress || 0
      const error = searchData?.data?.error

      if (
        error &&
        error?.message?.toString()?.includes('Total documents found is zero.')
      ) {
        status === 'ok'
        yield put({
          type: SET_LIT_SEARCH_STATE,
          state: {
            status: 'done',
            result: {
              smiles: '',
              data: [],
            },
          },
        })
        yield put({ type: REMOVE_NOTIFICATION, id: idForNotification })
        const notify = {
          id: uuidv4(),
          name: 'notification.literature_search_finished',
          autoRemove: true,
          notification_type: 'success',
          timeout: 5000,
        }

        yield put({
          type: ADD_NOTIFICATION,
          task: notify,
        })
      }
      yield put({
        type: UPDATE_NOTIFICATION,
        id: idForNotification,
        updated_data: {
          progress: progress,
        },
      })
      if (
        progress >= 10 &&
        searchData?.data?.result &&
        searchData.data.result.total !== paginationState
      ) {
        const config = {
          pagesAmount: Math.ceil(searchData.data.result.total / limit),
          perPage: limit,
          total: searchData.data.result.total,
          activePage: activePage,
        }
        yield put({
          type: SET_PAGINATION_CONFIG,
          config,
        })
        yield put({
          type: SET_LIT_SEARCH_RESULT,
          result: searchData.data,
          totalLitFound: searchData.data.result.total,
          status: 'loading',
        })
      }
      if (status === 'ok') {
        yield put({
          type: SET_LIT_SEARCH_RESULT,
          result: searchData.data,
          totalLitFound: searchData.data.result.total,
        })
        if (!task_uuid) {
          const searchHistoryData = yield fetch(
            `/search_history?offset=${offsetToUse}&limit=${limit}`
          )
          if (searchHistoryData) {
            const config = {
              total: searchHistoryData.data[0].result_count,
              pagesAmount: Math.ceil(
                searchHistoryData.data[0].result_count / limit
              ),
              perPage: limit,
              activePage: activePage,
            }

            yield put({
              type: SET_PAGINATION_CONFIG,
              config,
            })
          }

          const notify = {
            id: uuidv4(),
            name: 'notification.literature_search_finished',
            autoRemove: true,
            notification_type: 'success',
            timeout: 5000,
          }

          yield put({
            type: ADD_NOTIFICATION,
            task: notify,
          })
          yield put({ type: REMOVE_NOTIFICATION, id: idForNotification })
        }
      }
      yield delay(2000)
    }
  } catch (e) {
    console.log(e)
    yield put({ type: SET_LIT_SEARCH_ERROR })
    yield put({ type: REMOVE_NOTIFICATION, id: idForNotification })
  }
}

export function* checkCustomerDatasetsAccess() {
  try {
    const res = yield fetch('/customer/check_available_customer_baskets', 2)
    if (res.status === 200) {
      setStorage('showCustomerDatasets', true)
    } else {
      setStorage('showCustomerDatasets', false)
    }
  } catch (e) {
    console.log(e)
  }
}

export function* transferToCustomerDatasets({
  basket_ids = [],
  basketType,
  withCopy = false,
}) {
  if (!basket_ids.length) return
  const isMultiple = basket_ids.length > 1

  try {
    const res = yield store(
      withCopy ? '/copy-basket-to-customer/' : '/transfer-basket-to-customer/',
      {
        basket_ids,
      }
    )
    if (res.status === 200) {
      yield put({
        type: LOAD_BASKETS_INIT,
        withPublic: true,
        basketType,
      })
      yield put({
        type: SET_SELECTED_BASKET,
        value: [],
      })

      const notify = {
        id: uuidv4(),
        name: isMultiple
          ? 'notification.transfers_success'
          : 'notification.transfer_success',
        notification_type: 'success',
        autoRemove: true,
        timeout: 5000,
      }

      yield put({
        type: ADD_NOTIFICATION,
        task: notify,
      })
    }
  } catch (e) {
    console.log(e)
    const notify = {
      id: uuidv4(),
      name: isMultiple
        ? 'notification.transfers_error'
        : 'notification.transfer_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }

    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

export function* reactionsSearch({
  offset = 0,
  limit = REACTIONS_PAGINATION_LIMIT,
  compound_id: exactID,
  text,
  task_uuid,
}) {
  const pastResult = yield select((state) => state.searchHistory.pastResult)
  const leftFilterConfig = yield select((state) => state.filter.config)
  const compound_id = yield select((state) => state.reactionsSearch.compound_id)
  const paginationState = yield select((state) => state.search.pagination)
  const stateSearchText = yield select((state) => state.search.searchText)
  const textToUse = text || stateSearchText

  const { compound_types, yield: yield_range } = leftFilterConfig || {}

  const { activePage } = paginationState || {}
  try {
    let reactionsSearchData = []

    yield put({ type: SET_REACTIONS_SEARCH_LOADING })
    yield put({ type: SET_FILTER_BUTTONS_DISABLED })

    if (!task_uuid) {
      const request_body = {
        compound_types,
        ...(+yield_range[0] !== 0 || +yield_range[1] !== 100
          ? { yield_range: { from: +yield_range[0], to: +yield_range[1] } }
          : {}),
        offset,
        limit,
      }

      if (exactID) {
        const { data: search_data } = yield storeV2(
          `reaction/compound/${compound_id}`,
          request_body
        )
        reactionsSearchData = search_data
        yield put({
          type: SET_REACTIONS_COMPOUND_ID,
          compound_id: exactID ?? compound_id,
        })
      } else {
        const { data } = yield storeV2('molecule/global_search', {
          params: {
            quick: true,
            query: textToUse,
          },
        })
        const bestMatchId = data?.result?.items?.find(
          (el) => el.name === textToUse
        )?.id

        if (bestMatchId) {
          const { data: search_data } = yield storeV2(
            `reaction/compound/${bestMatchId}`,
            request_body
          )
          reactionsSearchData = search_data

          yield put({
            type: SET_REACTIONS_COMPOUND_ID,
            compound_id: bestMatchId,
          })
        }
      }
    } else {
      let status = 'initiated'
      while (status === 'initiated') {
        const searchData = yield fetch(
          `/search_history/${task_uuid}?offset=${offset}&limit=${limit}`
        )

        status = searchData.data.metadata.status

        if (status === 'ok') {
          reactionsSearchData = {
            items: searchData.data.result.data,
            count: searchData.data.result.total,
          }
          break
        }

        if (status === 'error') {
          throw new Error()
        }

        yield delay(2000)
      }
    }

    if (reactionsSearchData.count > limit) {
      const config = {
        pagesAmount: Math.ceil(reactionsSearchData.count / limit),
        perPage: limit,
        total: reactionsSearchData.count,
        activePage: activePage,
      }
      yield put({
        type: SET_PAGINATION_CONFIG,
        config,
      })
    }

    yield put({
      type: SET_REACTIONS_SEARCH_RESULT,
      result: reactionsSearchData,
    })
  } catch (e) {
    console.log(e)
    yield put({ type: SET_REACTIONS_SEARCH_ERROR })
  } finally {
    if (!pastResult) yield put({ type: SET_FILTER_BUTTONS_ACTIVE })
  }
}

export function* addBasketWatcher() {
  yield takeEvery(ADD_BASKET_INIT, addBasket)
}
export function* deleteBasketWatcher() {
  yield takeEvery(DELETE_BASKET_INIT, deleteBasket)
}
export function* copyBasketWatcher() {
  yield takeEvery(COPY_BASKET_INIT, copyBasket)
}
export function* joinBasketWatcher() {
  yield takeEvery(JOIN_BASKETS_INIT, joinBaskets)
}

export function* basketLoadWatcher() {
  yield takeLatest(LOAD_BASKETS_INIT, loadBasket)
}

export function* loadLastChangeWatcher() {
  yield takeLatest(LOAD_LAST_CHANGE_INIT, loadLastChange)
}

export function* moleculesLoadWatcher() {
  yield takeLatest(LOAD_MOLECULES_INIT, loadMolecules)
}

export function* moleculeCreateWatcher() {
  yield takeEvery(CREATE_MOLECULE_INIT, createMolecule)
}

export function* moleculeCreateFromFileWatcher() {
  yield takeEvery(CREATE_MOLECULES_FROM_FILE_INIT, createMoleculesFromFile)
}

export function* moleculeDeleteWatcher() {
  yield takeLatest(DELETE_MOLECULE_INIT, deleteMolecule)
}

export function* moleculeUpdateWatcher() {
  yield takeEvery(UPDATE_MOLECULE_INIT, updateMolecule)
}

export function* updateBasketWatcher() {
  yield takeEvery(UPDATE_BASKET_INIT, updateBasket)
}

export function* loadMoreMoleculesWatcher() {
  yield takeLeading(LOAD_MORE_MOLECULES_INIT, loadMoreMolecules)
}

export function* moveMoleculesWatcher() {
  yield takeEvery(MOVE_MOLECULE_INIT, moveMolecules)
}

export function* createMoleculesPackWatcher() {
  yield takeEvery(CREATE_MOLECULES_PACK_INIT, createMoleculesPack)
}

export function* calcTLightWatcher() {
  yield takeEvery(CALC_TLIGHT_INIT, calcTLight)
}

export function* searchV2Watcher() {
  yield takeEvery(SET_SEARCH_V2, searchV2)
}

export function* showSearchV2ResultWatcher() {
  yield takeEvery(SHOW_SEARCH_V2_RESULT, showSearchV2Result)
}

export function* bingoSearchWatcher() {
  yield takeEvery(SET_BINGO_SEARCH, bingoSearch)
}

export function* bingoMarkushSearchWatcher() {
  yield takeEvery(SET_BINGO_MARKUSH_SEARCH, bingoMarkushSearch)
}

export function* litSearchWatcher() {
  yield takeEvery(SET_LIT_SEARCH, literatureSearch)
}

export function* removeBasketFromDBWatcher() {
  yield takeEvery(REMOVE_BASKET_IDS_FROM_DB_INIT, removeBasketFromDB)
}

export function* checkCustomerDatasetsAccessWatcher() {
  yield takeEvery(CHECK_CUSTOMER_DATASETS_ACCESS, checkCustomerDatasetsAccess)
}

export function* transferToCustomerDatasetWatcher() {
  yield takeLatest(TRANSFER_TO_CUSTOMER_DATASETS, transferToCustomerDatasets)
}
export function* reactionsSearchWatcher() {
  yield takeEvery(SET_REACTIONS_SEARCH, reactionsSearch)
}
