import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  gap: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

export const ValuesWithSearch = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  width: 100%;
  margin-top: 0.5rem;
`
export const SelectedValuesContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0.75rem;
  gap: 0.5rem;
  width: 100%;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.backgrounds.primary};
`
