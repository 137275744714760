import { Suspense, lazy } from 'react'
import { Switch, Route } from 'react-router-dom'
import LoadingOverlay from '../components/LoadingOverlay'
import AuthRoute from './AuthRoute'
import AdminRoute from './AdminRoute'
import Loader from 'components/Loader'

const MoleculesList = lazy(() => import('../pages/MoleculesList'))
const NotFound = lazy(() => import('../components/NotFound'))
const IndividualPage = lazy(() => import('../components/IndividualPage'))
const SmilesIupacConverter = lazy(() =>
  import('../components/SmilesIupacConverter')
)
const LoginPage = lazy(() => import('../components/LoginPage'))
const StatisticsPage = lazy(() => import('../components/StatisticsPage'))
const RecoveryPasswordPage = lazy(() =>
  import('../components/RecoveryPasswordPage')
)
const Baskets = lazy(() => import('pages/Baskets'))
const SpectraPredictionPage = lazy(() => import('pages/SpectraPrediction'))
const MoleculePage = lazy(() => import('pages/MoleculePage'))
const SynthCost = lazy(() => import('pages/SynthCost'))
const Pdf2Smiles = lazy(() => import('pages/Pdf2Smiles'))
const Pdf2SmilesViewer = lazy(() => import('pages/Pdf2SmilesViewer'))
const LiteraturePage = lazy(() => import('pages/LiteraturePage'))
const ReactionListNew = lazy(() => import('pages/ReactionListNew'))
const AdminPanelPage = lazy(() => import('pages/AdminPanelPage'))
const AdminCompany = lazy(() => import('pages/AdminPanelPage/company'))
const RegistrationPageNew = lazy(() => import('components/RegistrationPageNew'))
const StructuresComparePage = lazy(() => import('pages/StructuresCompare'))
const Reinvent = lazy(() => import('../components/Reinvent'))

const Router = () => {
  return (
    <>
      <Loader />
      <Suspense fallback={<LoadingOverlay />}>
        <Switch>
          <AuthRoute path="/" exact component={MoleculesList} />
          <AuthRoute path="/search" exact component={MoleculesList} />
          <AuthRoute path="/datasets" exact component={Baskets} />
          <AuthRoute path="/molecules/:id" component={MoleculesList} />
          <AuthRoute path="/reactions" component={ReactionListNew} />
          <AuthRoute path="/spectra" component={SpectraPredictionPage} />
          <AuthRoute
            path="/molecules-compare"
            component={StructuresComparePage}
          />
          <AuthRoute path="/synmap" component={Reinvent} />
          <AuthRoute path="/smiles2iupac" component={SmilesIupacConverter} />
          <Route
            path={['/recovery/:id', '/recovery']}
            component={RecoveryPasswordPage}
          />
          <Route path={['/login/:id', '/login']} component={LoginPage} />
          <AuthRoute path="/statistics" component={StatisticsPage} />
          <Route
            path={['/profile/register', '/register']}
            component={RegistrationPageNew}
          />

          <AuthRoute path="/moleditor/:smiles?" component={IndividualPage} />
          <AuthRoute path="/molecule/:id" component={MoleculePage} />
          <AuthRoute path="/pdf2smiles" exact component={Pdf2Smiles} />
          <AuthRoute path="/pdf2smiles/:id?" component={Pdf2SmilesViewer} />
          <AuthRoute path="/literature/:type_id" component={LiteraturePage} />
          <AuthRoute path="/synthcost" component={SynthCost} />
          <AdminRoute exact path="/admin-panel" component={AdminPanelPage} />
          <AdminRoute path="/admin-panel/company" component={AdminCompany} />

          <Route component={NotFound} />
        </Switch>
      </Suspense>
    </>
  )
}

export default Router
