import styled, { css } from 'styled-components'

import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'

export const SelectContainer = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  width: ${({ width }) => width || '11.75rem'};
  height: 2.25rem;
  border-radius: 0.75rem;
  padding: 0.5rem 1rem 0.5rem 0.75rem;
  padding-left: ${({ paddingLeft }) => paddingLeft};
  outline: none;
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.primary
      : theme.colors.backgrounds.contextMenu};

  color: ${({ disabled, theme }) =>
    disabled ? theme.colors.text.tertiary : theme.colors.text.primary};

  font-size: 0.75rem;
  font-weight: 400;
  line-height: 1rem;
  cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')};

  &:hover {
    & > svg {
      color: ${({ disabledOpening, theme }) =>
        disabledOpening
          ? theme.colors.icons.tertiary
          : theme.colors.icons.accentPrimary};
    }
  }

  & > svg {
    color: ${({ isOpen, theme }) =>
      isOpen ? theme.colors.icons.accentPrimary : theme.colors.icons.tertiary};
  }
`

export const SelectList = styled.ul`
  position: absolute;
  margin: 0;
  padding: 0.25rem;

  list-style: none;
  background: ${({ theme }) => theme.colors.backgrounds.contextMenu};

  box-shadow: 0px 0px 8px 0px rgba(45, 55, 69, 0.2);

  backdrop-filter: blur(30px);

  border: ${({ theme }) => `1.2px solid ${theme.colors.icons.white}`};

  display: flex;
  flex-direction: column;
  gap: 0.25rem;

  border-radius: 0.75rem;
  width: 100%;
  left: 0;
  top: calc(100% + 0.5rem);
  z-index: 100;
  cursor: default;
`

export const SelectItem = styled.li`
  width: 100%;
  padding: 0.5rem;
  display: flex;
  align-items: center;
  gap: 0.625rem;
  cursor: pointer;
  border-radius: 0.5rem;
  &:hover {
    background: ${({ theme }) => theme.colors.backgrounds.contextMenuHover};
  }

  &:hover .radio-checkbox-label {
    color: ${({ theme }) => theme.colors.text.primary};
  }
`

export const ArrowDownSvg = styled(Icon)`
  transform: ${({ open }) => (open ? 'rotate(180deg)' : 'none')};
  transition: all 0.3s ease;
  color: inherit;
`

export const IconWrapper = styled.div`
  position: absolute;
  right: 0.25rem;
  border-radius: 0.5rem;
  padding: 0.375rem;
  max-width: 1.75rem;
  height: 1.75rem;
  background: ${({ withWhiteBackground, theme }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};
  color: ${({ isOpen, theme }) =>
    isOpen ? theme.colors.icons.accentPrimary : theme.colors.icons.tertiary};

  &:hover {
    color: ${({ theme }) => theme.colors.icons.accentPrimary};
  }
  ${({ isGroupBtn }) =>
    isGroupBtn &&
    css`
      margin-left: auto;
      position: relative;
      padding: 0;
      width: 1rem;
      height: 1rem;
      background: transparent;
    `}
`

export const Divider = styled.div`
  width: 100%;
  margin: 0.5rem;
  height: 0.0625rem;
  border-radius: 0.75rem;
  background-color: ${({ theme }) => theme.colors.icons.quaternary};
  z-index: 10;
`

export const SelectedValues = styled.div`
  width: 100%;
  overflow-x: auto;
  display: flex;
  gap: 0.375rem;
`

export const TagText = styled(Caption)`
  font-weight: ${({ theme }) => theme.fontWeight.medium};
  line-height: ${({ theme }) => theme.text[14]};
`

export const SelectedValue = styled.div`
  display: flex;
  height: 1.5rem;
  align-items: center;
  gap: 0.25rem;
  padding: 0.5rem;
  max-width: ${({ width }) => width || '1.875rem'};
  border-radius: 0.375rem;
  background: ${({ theme, withWhiteBackground }) =>
    withWhiteBackground
      ? theme.colors.backgrounds.secondary
      : theme.colors.backgrounds.primary};
  & ${TagText} {
    width: calc(100% - 1.25rem);
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }

  svg {
    min-width: 1rem;
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }
  &:hover {
    svg {
      color: ${({ theme }) => theme.colors.icons.accentPrimary};
    }
  }
`

export const Count = styled(SelectedValue)`
  max-width: 4rem;
  & ${TagText} {
    width: 100%;
  }
`
export const InputWithDivider = styled.div`
  width: 100%;
  padding-top: 0.5rem;
  &:first-child {
    padding-left: 0.5rem;
  }
`
