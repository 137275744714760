import { put, select, takeEvery } from 'redux-saga/effects'
import { storeV2 } from 'services/rest'
import {
  ADD_NEW_COMPANY_FAIL,
  ADD_NEW_COMPANY_INIT,
  ADD_NEW_COMPANY_SUCCESS,
  CLOSE_APPLICATION_DIALOG,
  CURRENT_CUSTOMER_EDIT_SUCCESS,
  CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS,
  CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS,
  CUSTOMER_EDIT_INIT,
  CUSTOMER_EDIT_SUCCESS,
  GET_APPLICATIONS_FAIL,
  GET_APPLICATIONS_INIT,
  GET_APPLICATIONS_SUCCESS,
  GET_CURRENT_CUSTOMER_INIT,
  GET_CURRENT_CUSTOMER_SUCCESS,
  GET_CURRENT_CUSTOMER_USERS_FAIL,
  GET_CURRENT_CUSTOMER_USERS_INIT,
  GET_CURRENT_CUSTOMER_USERS_SUCCESS,
  GET_CUSTOMERS_FAIL,
  GET_CUSTOMERS_INIT,
  GET_CUSTOMERS_SUCCESS,
  GET_USERS_FAIL,
  GET_USERS_INIT,
  GET_USERS_SUCCESS,
  LICENSE_TOGGLE_ACCESS_INIT,
  LICENSE_TOGGLE_ACCESS_SUCCESS,
  LICENSE_USER_TOGGLE_ACCESS_INIT,
  LICENSE_USER_TOGGLE_ACCESS_SUCCESS,
  REMOVE_USER_FROM_COMPANY_INIT,
  REMOVE_USER_FROM_COMPANY_SUCCESS,
  SET_ADD_COMPANY_STAGE,
  SET_APPLICATIONS_PAGINATION_CONFIG,
  SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
  SET_CUSTOMERS_PAGINATION_CONFIG,
  SET_USERS_PAGINATION_CONFIG,
  UPDATE_APPLICATION_FAIL,
  UPDATE_APPLICATION_INIT,
  UPDATE_APPLICATION_SUCCESS,
  UPDATE_CURRENT_CUSTOMER_LICENSE_USERS,
  REFRESH_CUSTOMER_TOKEN,
} from 'store/constants/adminPanel'
import { ADD_NOTIFICATION, ADD_SIMPLE_NOTIFICATION } from 'store/constants/notifications'
import { readStorage } from 'utils/storage/storage'
import { v4 as uuidv4 } from 'uuid'
import { getISOTimeZoneDate } from './utils/utils'

function* getCustomers({ limit, offset }) {
  try {
    const {
      status,
      input_value,
      license_started_at_from,
      license_started_at_to,
      license_expired_at_from,
      license_expired_at_to,
    } = yield select((state) => state.adminPanel.filter.customers) || {}
    const prepared_filters = {
      ...status?.filter_config,
      ...(input_value ? { name: input_value } : {}),
      ...(license_started_at_from ? { license_started_at_from } : {}),
      ...(license_started_at_to ? { license_started_at_to } : {}),
      ...(license_expired_at_from ? { license_expired_at_from } : {}),
      ...(license_expired_at_to ? { license_expired_at_to } : {}),
    }

    const { data } = yield storeV2('customer/get', {
      params: {
        limit,
        offset,
        ...(Object.keys(prepared_filters)?.length > 0
          ? { filter: prepared_filters }
          : {}),
      },
    })
    yield put({
      type: GET_CUSTOMERS_SUCCESS,
      total: data?.total,
      items: data?.items,
    })

    yield put({
      type: SET_CUSTOMERS_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.ceil(data?.total / limit),
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({
      type: GET_CUSTOMERS_FAIL,
    })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_companies_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* getCurrentCustomer({ isSynAdmin }) {
  try {
    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')
    const { data } = yield storeV2('customer/get', {
      params: {
        filter: { ids: [isSynAdmin ? currentCustomerId : storageCustomerId] },
      },
    })

    yield put({ type: GET_CURRENT_CUSTOMER_SUCCESS, items: data?.items })
  } catch (e) {
    console.log(e)
  }
}

function* getUsers({ limit, offset }) {
  try {
    const { activity_id_list, status, input_value } = yield select(
      (state) => state.adminPanel.filter.users
    ) || {}
    const prepared_filters = {
      ...status?.filter_config,
      ...(input_value
        ? { email: input_value, name: input_value, surname: input_value }
        : {}),
      ...(activity_id_list?.length
        ? { activity_id_list: activity_id_list.map((i) => i.value) }
        : {}),
    }

    const { data } = yield storeV2('user/get', {
      params: {
        limit,
        offset,
        ...(Object.keys(prepared_filters)?.length > 0
          ? { filter: prepared_filters }
          : {}),
      },
    })
    yield put({
      type: GET_USERS_SUCCESS,
      items: data?.result?.items,
      total: data?.result?.total,
    })

    yield put({
      type: SET_USERS_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.ceil(data?.result?.total / limit),
        total: data?.result?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({ type: GET_USERS_FAIL })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_users_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* getApplications({ limit, offset, isFirstRequest }) {
  try {
    let notHandledTotal = null
    const { data } = yield storeV2('license/request/get', {
      params: { limit, offset },
    })

    if (isFirstRequest) {
      const { data: notHandledData } = yield storeV2('license/request/get', {
        params: {
          limit: 1,
          offset: 0,
          filter: {
            is_handled: null,
          },
        },
      })
      notHandledTotal = notHandledData?.total
    }

    yield put({
      type: GET_APPLICATIONS_SUCCESS,
      total: data?.total,
      items: data?.items,
      isFirstRequest,
      notHandledTotal,
    })

    yield put({
      type: SET_APPLICATIONS_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.ceil(data?.total / limit),
        total: data?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({ type: GET_APPLICATIONS_FAIL })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_applications_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* updateApplication({ id, value, user_id, status }) {
  try {
    yield storeV2('license/request/update', {
      params: { id_: id, is_handled: value },
    })

    yield put({
      type: UPDATE_APPLICATION_SUCCESS,
      id,
      value,
      needUpdateTotal: status === null,
    })
    yield put({
      type: CLOSE_APPLICATION_DIALOG,
    })

    // если во вкладке с юзерами открыта страница, где есть юзер, заявка которого одобрена/отклонена, надо обновить данные
    const { items: users, pagination: usersPagination } = yield select(
      (state) => state.adminPanel.users
    )

    if (users?.some((el) => el.user_id === user_id)) {
      yield put({
        type: GET_USERS_INIT,
        limit: usersPagination.perPage,
        offset: (usersPagination.activePage - 1) * usersPagination.perPage,
      })
    }
  } catch (e) {
    console.log(e)
    yield put({ type: UPDATE_APPLICATION_FAIL })

    const id = uuidv4()
    const notify = {
      id,
      name: value
        ? 'admin.approve_application_error'
        : 'admin.reject_application_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* getCurrentCustomerUsers({ isSynAdmin, limit, offset }) {
  try {
    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')

    const { data } = yield storeV2('user/get', {
      params: {
        limit,
        offset,
        filter: {
          customer_id: isSynAdmin ? currentCustomerId : storageCustomerId,
        },
      },
    })
    yield put({
      type: GET_CURRENT_CUSTOMER_USERS_SUCCESS,
      items: data?.result?.items,
    })

    yield put({
      type: SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
      config: {
        pagesAmount: Math.ceil(data?.result?.total / limit),
        total: data?.result?.total,
        perPage: limit,
        activePage: offset / limit + 1,
      },
    })
  } catch (e) {
    console.log(e)
    yield put({ type: GET_CURRENT_CUSTOMER_USERS_FAIL })

    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.get_users_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* customerUpdate({ params }) {
  try {
    const { isCurrentCustomerPage, ...restParams } = params
    const {
      companyName,
      adminEmail,
      calendarValues,
      accsNumber,
      licenseId,
      customerId,
    } = restParams

    const { data: licenseData } = yield storeV2('license/update', {
      params: {
        id: licenseId,
        started_at: getISOTimeZoneDate(calendarValues[0]),

        expired_at: getISOTimeZoneDate(calendarValues[1]),

        max_users_count: accsNumber === '' ? null : accsNumber,
      },
    })
    const { data: customerData } = yield storeV2('customer/update', {
      params: {
        id: customerId,
        admin: adminEmail,
        name: companyName,
      },
    })

    yield put({
      type: CUSTOMER_EDIT_SUCCESS,
      items: {
        licenseResult: licenseData.result,
        customerResult: customerData.result,
      },
    })
    if (isCurrentCustomerPage) {
      yield put({
        type: CURRENT_CUSTOMER_EDIT_SUCCESS,
        items: {
          licenseResult: licenseData.result,
          customerResult: customerData.result,
        },
      })
    }
  } catch (e) {
    console.log(e)
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.customer_update_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* licenseToggleAccess({ params }) {
  try {
    const { isCurrentCustomer, ...restParams } = params
    const { data } = yield storeV2('license/update', { params: restParams })

    yield put({
      type: LICENSE_TOGGLE_ACCESS_SUCCESS,
      items: { id: data?.result?.id, is_active: data?.result?.is_active },
    })
    if (isCurrentCustomer) {
      yield put({
        type: CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS,
        items: { id: data?.result?.id, is_active: data?.result?.is_active },
      })
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.toggle_access_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* licenseUserToggleAccess({ params }) {
  try {
    const { isCurrentCustomer, license_id, customer_id, user_id } = params
    let customerData = null
    if (license_id === null) {
      customerData = yield storeV2('customer/get', {
        params: {
          filter: { ids: [customer_id] },
        },
      })
    }

    const { data } = yield storeV2('user/update', {
      params: {
        user_id,
        license_id: license_id
          ? null
          : customerData?.data?.items?.[0]?.license_id,
      },
    })

    yield put({
      type: LICENSE_USER_TOGGLE_ACCESS_SUCCESS,
      items: {
        user_id: data?.result?.user_id,
        license_id: data?.result?.license_id,
        customer_id: data?.result?.customer_id,
      },
    })
    if (isCurrentCustomer) {
      yield put({
        type: CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS,
        items: {
          user_id: data?.result?.user_id,
          license_id: data?.result?.license_id,
        },
      })

      // если лицензия обновилась, меняем количество юзеров на странице компании
      if (license_id !== data?.result?.license_id) {
        yield put({
          type: UPDATE_CURRENT_CUSTOMER_LICENSE_USERS,
          withLicense: !!data?.result?.license_id,
        })
      }
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.toggle_access_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* removeUserFromCompany({ params }) {
  try {
    const { isCurrentCustomer, user_id, isSynAdmin } = params

    const { activePage, perPage } = yield select(
      (state) => state.adminPanel.currentCustomerUsers.pagination
    )

    const { data } = yield storeV2('user/update', {
      params: {
        user_id,
        license_id: null,
        customer_id: null,
      },
    })

    yield put({
      type: REMOVE_USER_FROM_COMPANY_SUCCESS,
      items: {
        user_id: data?.result?.user_id,
      },
    })

    if (isCurrentCustomer) {
      yield put({
        type: GET_CURRENT_CUSTOMER_USERS_INIT,
        isSynAdmin,
        offset: (activePage - 1) * perPage,
        limit: perPage,
      })
    }
  } catch (e) {
    const id = uuidv4()
    const notify = {
      id,
      name: 'admin.remove_user_error',
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* addNewCompany({ params }) {
  try {
    const { data } = yield storeV2('customer/create', { params })
    yield put({ type: ADD_NEW_COMPANY_SUCCESS, item: data?.result })
    yield put({ type: SET_ADD_COMPANY_STAGE, stage: 'success' })
  } catch (e) {
    console.log(e)

    yield put({
      type: ADD_NEW_COMPANY_FAIL,
    })

    const errMsg = e?.response?.data?.result?.error_message
    const getErrorName = () => {
      if (
        errMsg &&
        errMsg.includes('Customer with name') &&
        errMsg.includes('already exists')
      ) {
        return 'admin.add_company_error_name_exists'
      }
      return 'admin.add_company_error'
    }

    const id = uuidv4()
    const notify = {
      id,
      name: getErrorName(),
      notification_type: 'error',
      autoRemove: true,
      timeout: 5000,
    }
    yield put({
      type: ADD_NOTIFICATION,
      task: notify,
    })
  }
}

function* refreshCustomerToken({ params }) {
  try {
    const { companyName, adminEmail, customerId, isSynAdmin } = params

    const { data: customerData } = yield storeV2('customer/update', {
      params: {
        id: customerId,
        admin: adminEmail,
        name: companyName,
        refresh_invite_token: true,
      },
    })

    const currentCustomerId = yield select(
      (state) => state.adminPanel.currentCustomerId
    )
    const storageCustomerId = readStorage('customer_id')
    const { data } = yield storeV2('customer/get', {
      params: {
        filter: { ids: [isSynAdmin ? currentCustomerId : storageCustomerId] },
      },
    })

    yield put({ type: GET_CURRENT_CUSTOMER_SUCCESS, items: data?.items })

    yield put({
      type: ADD_SIMPLE_NOTIFICATION,
      text: 'notification.product_key_refreshed',
    })
  } catch (e) {
    console.log(e)
  }
}

export function* getCustomersWatcher() {
  yield takeEvery(GET_CUSTOMERS_INIT, getCustomers)
}

export function* licenseToggleAccessWatcher() {
  yield takeEvery(LICENSE_TOGGLE_ACCESS_INIT, licenseToggleAccess)
}

export function* licenseUserToggleAccessWatcher() {
  yield takeEvery(LICENSE_USER_TOGGLE_ACCESS_INIT, licenseUserToggleAccess)
}

export function* removeUserFromCompanyWatcher() {
  yield takeEvery(REMOVE_USER_FROM_COMPANY_INIT, removeUserFromCompany)
}

export function* getUsersWatcher() {
  yield takeEvery(GET_USERS_INIT, getUsers)
}

export function* getApplicationsWatcher() {
  yield takeEvery(GET_APPLICATIONS_INIT, getApplications)
}

export function* updateApplicationWatcher() {
  yield takeEvery(UPDATE_APPLICATION_INIT, updateApplication)
}

export function* getCurrentCustomerUsersWatcher() {
  yield takeEvery(GET_CURRENT_CUSTOMER_USERS_INIT, getCurrentCustomerUsers)
}

export function* customerUpdateWatcher() {
  yield takeEvery(CUSTOMER_EDIT_INIT, customerUpdate)
}
export function* getCurrentCustomerWatcher() {
  yield takeEvery(GET_CURRENT_CUSTOMER_INIT, getCurrentCustomer)
}

export function* addNewCompanyWatcher() {
  yield takeEvery(ADD_NEW_COMPANY_INIT, addNewCompany)
}

export function* refreshCustomerTokenWatcher() {
  yield takeEvery(REFRESH_CUSTOMER_TOKEN, refreshCustomerToken)
}

const watchers = [
  getCustomersWatcher(),
  licenseToggleAccessWatcher(),
  licenseUserToggleAccessWatcher(),
  removeUserFromCompanyWatcher(),
  getUsersWatcher(),
  getApplicationsWatcher(),
  updateApplicationWatcher(),
  getCurrentCustomerUsersWatcher(),
  customerUpdateWatcher(),
  getCurrentCustomerWatcher(),
  addNewCompanyWatcher(),
  refreshCustomerTokenWatcher(),
]

export default watchers
