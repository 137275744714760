import { useGridFilter } from 'ag-grid-react'
import { useTranslation } from 'react-i18next'
import { memo, useCallback, useEffect, useState } from 'react'

import CustomSwitch from 'components/common/customSwitch'

import FilterContent from './FilterContent'
import { FiltersWrapper } from './index.style'
import PaintingContent from './PaintingContent'
import { checkFilterConditions } from './utils/utils'
import {
  CUSTOM_FILTER_CONFIG,
  numberFiltersConfig,
  textFiltersConfig,
} from './config/config'

const CustomFilter = memo(
  ({
    api,
    model,
    column,
    getValue,
    columnType,
    paintingModel,
    onChangePaintingModel,
  }) => {
    const { t } = useTranslation()
    const [mode, setMode] = useState('filter')
    const [values, setValues] = useState([])
    const [filterOperator, setFilterOperator] = useState(null)
    const [isFilterOpened, setIsFilterOpened] = useState(false)

    // примененные фильтры ("contains" и тд)
    const appliedFilter = model?.filterModels?.find(
      (el) => el?.conditions || (el?.type && el?.type !== 'set')
    )

    // примененные значения-исключения (когда отжали чекбокс в списке)
    const filterException = model?.filterModels?.find(
      (el) => el?.type === 'set'
    )

    const config =
      columnType === 'string' ? textFiltersConfig : numberFiltersConfig

    const typeOptions = [
      ...config.map((el) => ({
        value: el.type,
        label: `dataset_table.${el.type}`,
      })),
      { value: '', label: 'dataset_table.not_selected' },
    ]

    useEffect(() => {
      setTimeout(() => getValues(), 0)
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [model])

    useEffect(() => {
      if (isFilterOpened) getValues()
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isFilterOpened])

    // определение, какие значения подходят под фильтры
    const doesFilterPass = useCallback(
      (params) => {
        const { node } = params

        const value = getValue(node)
        if (!model) return true

        return (
          checkFilterConditions(
            value,
            appliedFilter,
            columnType,
            filterOperator
          ) && !(filterException?.values || []).includes(value)
        )
      },
      [model]
    )

    const afterGuiAttached = useCallback(() => {
      setIsFilterOpened(true)
    }, [])

    const afterGuiDetached = useCallback(() => {
      setIsFilterOpened(false)
    }, [])

    useGridFilter({
      doesFilterPass,
      afterGuiAttached,
      afterGuiDetached,
    })

    // получение всех значений в столбце после примененных фильтров
    const getValues = async () => {
      let newValues = []
      await api.forEachNodeAfterFilter((node) => {
        if (!!node?.data && node.data?.[column.colId] !== undefined) {
          newValues.push(node.data[column.colId])
        }
      })
      setValues(Array.from(new Set(newValues)))
    }

    return (
      <FiltersWrapper>
        <CustomSwitch
          active={mode}
          bg="white"
          btnWidth="50%"
          wrapperWidth="100%"
          items={CUSTOM_FILTER_CONFIG}
          onClick={setMode}
        />

        {mode === 'filter' && (
          <FilterContent
            {...{
              api,
              model,
              values,
              column,
              columnType,
              typeOptions,
              appliedFilter,
              isFilterOpened,
              filterOperator,
              filterException,
              setFilterOperator,
            }}
          />
        )}
        {mode === 'painting' && (
          <PaintingContent
            {...{
              values,
              column,
              typeOptions,
              paintingModel,
              onChangePaintingModel,
            }}
          />
        )}
      </FiltersWrapper>
    )
  }
)

CustomFilter.displayName = 'customFilter'

export default CustomFilter
