export const getFileType = (type, name) => {
  if (!type) return name?.split('.')?.slice(-1)?.[0]
  const t = type.toLowerCase()

  if (t.includes('csv')) return 'csv'
  if (t.includes('sdf')) return 'sdf'
  if (t.includes('smi')) return 'smi'
  if (t.includes('pdf')) return 'pdf'
  return ''
}
