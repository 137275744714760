export const GET_CUSTOMERS_INIT = 'GET_CUSTOMERS_INIT'
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS'
export const GET_CUSTOMERS_FAIL = 'GET_CUSTOMERS_FAIL'

export const GET_CURRENT_CUSTOMER_INIT = 'GET_CURRENT_CUSTOMER_INIT'
export const GET_CURRENT_CUSTOMER_SUCCESS = 'GET_CURRENT_CUSTOMER_SUCCESS'
export const GET_CURRENT_CUSTOMER_FAIL = 'GET_CURRENT_CUSTOMER_FAIL'

export const LICENSE_TOGGLE_ACCESS_INIT = 'LICENSE_TOGGLE_ACCESS_INIT'
export const LICENSE_TOGGLE_ACCESS_SUCCESS = 'LICENSE_TOGGLE_ACCESS_SUCCESS'
export const LICENSE_TOGGLE_ACCESS_FAIL = 'LICENSE_TOGGLE_ACCESS_FAIL'

export const LICENSE_USER_TOGGLE_ACCESS_INIT = 'LICENSE_USER_TOGGLE_ACCESS_INIT'
export const LICENSE_USER_TOGGLE_ACCESS_SUCCESS =
  'LICENSE_USER_TOGGLE_ACCESS_SUCCESS'

export const REMOVE_USER_FROM_COMPANY_INIT = 'REMOVE_USER_FROM_COMPANY_INIT'
export const REMOVE_USER_FROM_COMPANY_SUCCESS =
  'REMOVE_USER_FROM_COMPANY_SUCCESS'

export const CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS =
  'CURRENT_CUSTOMER_LICENSE_USER_TOGGLE_SUCCESS'

export const CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS =
  'CURRENT_CUSTOMER_LICENSE_TOGGLE_SUCCESS'

export const UPDATE_CURRENT_CUSTOMER_LICENSE_USERS =
  'UPDATE_CURRENT_CUSTOMER_LICENSE_USERS'

export const CUSTOMER_EDIT_INIT = 'CUSTOMER_EDIT_INIT'
export const CUSTOMER_EDIT_SUCCESS = 'CUSTOMER_EDIT_SUCCESS'
export const CUSTOMER_EDIT_FAIL = 'CUSTOMER_EDIT_FAIL'
export const CURRENT_CUSTOMER_EDIT_SUCCESS = 'CURRENT_CUSTOMER_EDIT_SUCCESS'

export const GET_USERS_INIT = 'GET_USERS_INIT'
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS'
export const GET_USERS_FAIL = 'GET_USERS_FAIL'

export const GET_APPLICATIONS_INIT = 'GET_APPLICATIONS_INIT'
export const GET_APPLICATIONS_SUCCESS = 'GET_APPLICATIONS_SUCCESS '
export const GET_APPLICATIONS_FAIL = 'GET_APPLICATIONS_FAIL'

export const OPEN_APPLICATION_DIALOG = 'OPEN_APPLICATION_DIALOG'
export const CLOSE_APPLICATION_DIALOG = 'CLOSE_APPLICATION_DIALOG'

export const UPDATE_APPLICATION_INIT = 'UPDATE_APPLICATION_INIT'
export const UPDATE_APPLICATION_SUCCESS = 'UPDATE_APPLICATION_SUCCESS '
export const UPDATE_APPLICATION_FAIL = 'UPDATE_APPLICATION_FAIL'

export const GET_CURRENT_CUSTOMER_USERS_INIT = 'GET_CURRENT_CUSTOMER_USERS_INIT'
export const GET_CURRENT_CUSTOMER_USERS_SUCCESS =
  'GET_CURRENT_CUSTOMER_USERS_SUCCESS'
export const GET_CURRENT_CUSTOMER_USERS_FAIL = 'GET_CURRENT_CUSTOMER_USERS_FAIL'

export const SET_ADMIN_PANEL_SECTION = 'SET_ADMIN_PANEL_SECTION'
export const SET_ADMIN_PANEL_FILTER_TYPE = 'SET_ADMIN_PANEL_FILTER_TYPE'

export const SET_LICENSE_ACTIVATION_DATE = 'SET_LICENSE_ACTIVATION_DATE'
export const SET_LICENSE_EXPIRATION_DATE = 'SET_LICENSE_EXPIRATION_DATE'

export const SET_FILTER_STATUS = 'SET_FILTER_STATUS'

export const SET_USERS_PAGINATION_CONFIG = 'SET_USERS_PAGINATION_CONFIG'
export const SET_APPLICATIONS_PAGINATION_CONFIG =
  'SET_APPLICATIONS_PAGINATION_CONFIG'
export const SET_CURRENT_CUSTOMER_PAGINATION_CONFIG =
  'SET_CURRENT_CUSTOMER_PAGINATION_CONFIG'
export const SET_CUSTOMERS_PAGINATION_CONFIG = 'SET_CUSTOMERS_PAGINATION_CONFIG'

export const SET_CURRENT_CUSTOMER_ID = 'SET_CURRENT_CUSTOMER_ID'

export const OPEN_ADD_COMPANY_DIALOG = 'OPEN_ADD_COMPANY_DIALOG'
export const CLOSE_ADD_COMPANY_DIALOG = 'CLOSE_ADD_COMPANY_DIALOG'

export const SET_ADD_COMPANY_STAGE = 'SET_ADD_COMPANY_STAGE'

export const ADD_NEW_COMPANY_INIT = 'ADD_NEW_COMPANY_INIT'
export const ADD_NEW_COMPANY_SUCCESS = 'ADD_NEW_COMPANY_SUCCESS'
export const ADD_NEW_COMPANY_FAIL = 'ADD_NEW_COMPANY_FAIL'

export const SET_ADMIN_USERS_FILTER = 'SET_ADMIN_USERS_FILTER'
export const SET_ADMIN_CUSTOMERS_FILTER = 'SET_ADMIN_CUSTOMERS_FILTER'
export const CLEAR_ADMIN_FILTER = 'CLEAR_ADMIN_FILTER'

export const REFRESH_CUSTOMER_TOKEN = 'REFRESH_CUSTOMER_TOKEN'
