import {
  ADD_NEW_COMPANY_INIT,
  CLEAR_ADMIN_FILTER,
  CLOSE_ADD_COMPANY_DIALOG,
  CLOSE_APPLICATION_DIALOG,
  CUSTOMER_EDIT_INIT,
  GET_APPLICATIONS_INIT,
  GET_CURRENT_CUSTOMER_INIT,
  GET_CURRENT_CUSTOMER_USERS_INIT,
  GET_CUSTOMERS_INIT,
  GET_USERS_INIT,
  LICENSE_TOGGLE_ACCESS_INIT,
  LICENSE_USER_TOGGLE_ACCESS_INIT,
  OPEN_ADD_COMPANY_DIALOG,
  OPEN_APPLICATION_DIALOG,
  REFRESH_CUSTOMER_TOKEN,
  REMOVE_USER_FROM_COMPANY_INIT,
  SET_ADD_COMPANY_STAGE,
  SET_ADMIN_CUSTOMERS_FILTER,
  SET_ADMIN_PANEL_FILTER_TYPE,
  SET_ADMIN_PANEL_SECTION,
  SET_ADMIN_USERS_FILTER,
  SET_APPLICATIONS_PAGINATION_CONFIG,
  SET_CURRENT_CUSTOMER_ID,
  SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
  SET_CUSTOMERS_PAGINATION_CONFIG,
  SET_FILTER_STATUS,
  SET_LICENSE_ACTIVATION_DATE,
  SET_LICENSE_EXPIRATION_DATE,
  SET_USERS_PAGINATION_CONFIG,
  UPDATE_APPLICATION_INIT,
  UPDATE_CURRENT_CUSTOMER_LICENSE_USERS,
} from 'store/constants/adminPanel'

export const getCustomers = ({ limit, offset }) => {
  return {
    type: GET_CUSTOMERS_INIT,
    limit,
    offset,
  }
}

export const getCurrentCustomer = ({ isSynAdmin }) => {
  return {
    type: GET_CURRENT_CUSTOMER_INIT,
    isSynAdmin,
  }
}

export const updateCurrentCustomerLicenseUsers = (withLicense) => {
  return {
    type: UPDATE_CURRENT_CUSTOMER_LICENSE_USERS,
    withLicense,
  }
}

export const getUsers = ({ limit, offset }) => {
  return {
    type: GET_USERS_INIT,
    offset,
    limit,
  }
}

export const getApplications = ({ limit, offset, isFirstRequest }) => {
  return {
    type: GET_APPLICATIONS_INIT,
    offset,
    limit,
    isFirstRequest,
  }
}

export const openApplicationDialog = (id) => {
  return {
    type: OPEN_APPLICATION_DIALOG,
    id,
  }
}

export const closeApplicationDialog = () => {
  return {
    type: CLOSE_APPLICATION_DIALOG,
  }
}

export const updateApplication = (id, value, user_id, status) => {
  return {
    type: UPDATE_APPLICATION_INIT,
    id,
    value,
    user_id,
    status,
  }
}

export const getCurrentCustomerUsers = ({ isSynAdmin, limit, offset }) => {
  return {
    type: GET_CURRENT_CUSTOMER_USERS_INIT,
    isSynAdmin,
    offset,
    limit,
  }
}

export const customerUpdate = (params) => {
  return {
    type: CUSTOMER_EDIT_INIT,
    params,
  }
}

export const licenseToggleAccess = (params) => {
  return {
    type: LICENSE_TOGGLE_ACCESS_INIT,
    params,
  }
}

export const licenseUserToggleAccess = (params) => {
  return {
    type: LICENSE_USER_TOGGLE_ACCESS_INIT,
    params,
  }
}

export const removeUserFromCompany = (params) => {
  return {
    type: REMOVE_USER_FROM_COMPANY_INIT,
    params,
  }
}

export const setAdminPanelSection = (section) => {
  return {
    type: SET_ADMIN_PANEL_SECTION,
    section,
  }
}

export const setAdminPanelFilterType = (filterType) => {
  return {
    type: SET_ADMIN_PANEL_FILTER_TYPE,
    filterType,
  }
}

export const setLicenseActivationDate = (date, inputType) => {
  return {
    type: SET_LICENSE_ACTIVATION_DATE,
    date,
    inputType,
  }
}

export const setLicenseExpirationDate = (date, inputType) => {
  return {
    type: SET_LICENSE_EXPIRATION_DATE,
    date,
    inputType,
  }
}

export const setFilterStatus = (status) => {
  return {
    type: SET_FILTER_STATUS,
    status,
  }
}

export const setUsersPaginationConfig = (config) => {
  return {
    type: SET_USERS_PAGINATION_CONFIG,
    config,
  }
}

export const setApplicationsPaginationConfig = (config) => {
  return {
    type: SET_APPLICATIONS_PAGINATION_CONFIG,
    config,
  }
}

export const setCurrentCustomerUsersPaginationConfig = (config) => {
  return {
    type: SET_CURRENT_CUSTOMER_PAGINATION_CONFIG,
    config,
  }
}

export const setCustomersPaginationConfig = (config) => {
  return {
    type: SET_CUSTOMERS_PAGINATION_CONFIG,
    config,
  }
}

export const setCurrentCustomerId = ({ customerId, licenseId }) => {
  return { type: SET_CURRENT_CUSTOMER_ID, customerId, licenseId }
}

export const openAddCompanyDialog = () => {
  return {
    type: OPEN_ADD_COMPANY_DIALOG,
  }
}

export const closeAddCompanyDialog = () => {
  return {
    type: CLOSE_ADD_COMPANY_DIALOG,
  }
}

export const setAddCompanyStage = (stage) => {
  return { type: SET_ADD_COMPANY_STAGE, stage }
}

export const addNewCompany = (params) => {
  return { type: ADD_NEW_COMPANY_INIT, params }
}

export const setAdminFilter = ({ filter_type, key, value }) => {
  return {
    type:
      filter_type === 'users'
        ? SET_ADMIN_USERS_FILTER
        : SET_ADMIN_CUSTOMERS_FILTER,
    key,
    value,
  }
}

export const clearAdminFilter = () => {
  return {
    type: CLEAR_ADMIN_FILTER,
  }
}

export const refreshCustomerToken = (params) => {
  return {
    type: REFRESH_CUSTOMER_TOKEN,
    params,
  }
}
