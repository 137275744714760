import styled from 'styled-components'

export const FilterCheckboxesWrapper = styled.div`
  display: flex;
  flex-direction: column;
`

export const FilterCheckboxesTitle = styled.div`
  margin-bottom: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 11px;
  font-weight: 400;
  line-height: 14px;
  color: ${({ theme }) => theme.colors.text.secondary};
  cursor: pointer;
`

export const FilterCheckboxesContainer = styled.div`
  height: ${({ opened }) => (opened ? '100%' : '0px')};
  overflow: ${({ opened }) => (opened ? 'unset' : 'hidden')};
  min-height: ${({ opened }) => (opened ? '2.5rem' : '0px')};
  opacity: ${({ opened }) => (opened ? '1' : '0')};
  transition: all 0.3s;
  display: flex;
  column-gap: 0.5rem;
`

export const FilterCheckboxItems = styled.div`
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
`
