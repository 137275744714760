export const SYNTHCOST_GET_TASK_INIT = 'SYNTHCOST_GET_TASK_INIT'
export const SYNTHCOST_GET_TASK_FAIL = 'SYNTHCOST_GET_TASK_FAIL'
export const SYNTHCOST_GET_TASK_SUCCESS = 'SYNTHCOST_GET_TASK_SUCCESS'

export const SYNTHCOST_GET_RESULT_INIT = 'SYNTHCOST_GET_RESULT_INIT'
export const SYNTHCOST_GET_RESULT_FAIL = 'SYNTHCOST_GET_RESULT_FAIL'
export const SYNTHCOST_GET_RESULT_SUCCESS = 'SYNTHCOST_GET_RESULT_SUCCESS'

export const SYNTHCOST_RESET_PREDICTION = 'SYNTHCOST_RESET_PREDICTION'

export const SYNTHCOST_SAVE_SETTINGS = 'SYNTHCOST_SAVE_SETTINGS'
export const SYNTHCOST_RESET_SETTINGS = 'SYNTHCOST_RESET_SETTINGS'

export const SYNTHCOST_RESET_TASK = 'SYNTHCOST_RESET_TASK'

export const SYNTHCOST_RESET_ERROR = 'SYNTHCOST_RESET_ERROR'

export const SYNTHCOST_SET_WARNING_NOTIFY_IDS =
  'SYNTHCOST_SET_WARNING_NOTIFY_IDS'

export const SYNTHCOST_REMOVE_WARNING_ID = 'SYNTHCOST_REMOVE_WARNING_ID'
