import { useRef } from 'react'
import { useTheme } from 'styled-components'
import { Tooltip as ReactTooltip } from 'react-tooltip'

import Icon from 'components/Icon'
import { Caption } from 'components/common/text/index.style'

import {
  FileName,
  DeleteBtn,
  TypeWithSize,
  UploadedFileContainer,
  NameWithInfo,
} from './index.style'
import { getFileType } from './utils/utils'

const UploadedFile = ({ type, name, size, onDelete }) => {
  const nameRef = useRef()
  const theme = useTheme()

  const fileType = getFileType(type, name)

  const sizeInKb = (size / 1024).toFixed(1)
  const sizeInMb = (sizeInKb / 1024).toFixed(1)

  return (
    <UploadedFileContainer>
      <Icon iconType={fileType} size="2rem" />
      <NameWithInfo>
        <FileName ref={nameRef} data-tooltip-id="file-info-name">
          {name}
        </FileName>
        {nameRef?.current?.scrollHeight > nameRef?.current?.clientHeight && (
          <ReactTooltip
            id={'file-info-name'}
            className="c-tooltip c-tooltip-base c-tooltip-filename"
            classNameArrow="c-tooltip-arrow"
            place={'bottom'}
          >
            {name}
          </ReactTooltip>
        )}
        <TypeWithSize>
          <Caption
            lineHeight={theme.text[14]}
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.medium}
          >
            {fileType.toUpperCase()}
          </Caption>

          <Caption
            lineHeight={theme.text[14]}
            color={theme.colors.text.secondary}
            fontWeight={theme.fontWeight.medium}
          >
            {sizeInMb > 0.1 ? `${sizeInMb} mb` : `${sizeInKb} kb`}
          </Caption>
        </TypeWithSize>
      </NameWithInfo>
      <DeleteBtn
        type="icon_primary"
        className="delete-file-btn"
        onClick={onDelete}
        data-test="file-remove-btn"
      >
        <Icon iconType="deleted" size="1rem" />
      </DeleteBtn>
    </UploadedFileContainer>
  )
}

export default UploadedFile
