import React, { Fragment, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { DIALOG_PREFIX } from './config/config'
import { ButtonsWrapper, Content, HeaderWrapper } from './index.style'

import {
  CustomDialog,
  CustomDialogHeader,
} from 'components/common/customDialog'
import Icon from 'components/Icon'
import { fetch } from 'services/rest'
import CustomButton from 'components/common/customButton'

const ExportBasketDialog = ({
  datasetId,
  datasetName,
  tasksStatus,
  handleClose,
  handleDownload,
  calculateForExport,
  handleShowDatasetTable,
  type = 'export',
}) => {
  const { t } = useTranslation()
  const [isReady, setIsReady] = useState(false) //now init value is always false, cause we have no isCalculated value
  const [isDisabledBtns, setIsDisabledBtns] = useState(false)
  const [isCalculatedChecked, setCalculatedChecked] = useState(false)
  const [isCsvReady, setIsCsvReady] = useState(false)

  const hasRunningTask =
    tasksStatus?.length > 0 &&
    tasksStatus?.some(
      (task) => task?.basket_id === datasetId && task?.progress !== 100
    )

  const prepareButtonsConfig = [
    {
      text: 'calculate',
      width: type === 'export' ? '50%' : '100%',
      onClick: () => {
        setIsDisabledBtns(true)
        calculateForExport()
        setTimeout(() => handleClose(), 500)
      },
      disabled: hasRunningTask,
    },
    {
      text: 'export',
      width: '50%',
      hidden: type === 'analysis',
      onClick: () => {
        if (!isReady) {
          setIsReady(true)
        }
      },
    },
  ]

  const exportButtonsConfig = [
    {
      text: 'export_in_CSV',
      onClick: () => setIsCsvReady(true),
    },
    {
      text: 'export_in_SDF',
      onClick: () => handleDownload('sdf'),
    },
    {
      text: 'export_in_XLSX',
      onClick: () => handleDownload('xlsx'),
    },
  ]

  const handleDownloadCsv = () => {
    handleDownload('csv')
    setIsCsvReady(false)
  }

  useEffect(() => {
    handleCheckBasketCalculation()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    if (isReady && type === 'analysis') {
      handleClose()
      handleShowDatasetTable()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, type])

  const handleCheckBasketCalculation = async () => {
    try {
      const res = await fetch(`is_basket_calculated?basket_id=${datasetId}`)
      if (res?.data?.result?.is_calculated) setIsReady(true)
    } catch (err) {
      console.log(err)
    } finally {
      setCalculatedChecked(true)
    }
  }

  if (!isCalculatedChecked) return null

  return (
    <CustomDialog
      onClose={handleClose}
      width={'424px'}
      withPortal={true}
      outboundExceptions={['custom-snackbar-outbound-exception']}
    >
      {isReady ? (
        <Fragment>
          <CustomDialogHeader>
            {isCsvReady
              ? t(`${DIALOG_PREFIX}exporting_set_in_csv_format`)
              : t(`${DIALOG_PREFIX}set_export`)}
          </CustomDialogHeader>
          <Content>
            {isCsvReady
              ? t(`${DIALOG_PREFIX}csv_warning`)
              : t(`${DIALOG_PREFIX}select_the_desired_download_file_format`)}
          </Content>
          <ButtonsWrapper>
            {isCsvReady ? (
              <CustomButton
                onClick={handleDownloadCsv}
                type="secondary"
                width={'100%'}
              >
                {t(`${DIALOG_PREFIX}ok`)}
              </CustomButton>
            ) : (
              exportButtonsConfig.map(({ text, onClick }, i) => (
                <CustomButton
                  key={i}
                  onClick={onClick}
                  type="secondary"
                  width={'50%'}
                >
                  {t(`${DIALOG_PREFIX}${text}`)}
                </CustomButton>
              ))
            )}
          </ButtonsWrapper>
        </Fragment>
      ) : (
        <Fragment>
          <HeaderWrapper>
            <Icon iconType={'warning'} />
            <CustomDialogHeader>{`${t(
              `${DIALOG_PREFIX}set`
            )} ${datasetName} ${t(
              `${DIALOG_PREFIX}has_uncalculated_molecules`
            )}`}</CustomDialogHeader>
          </HeaderWrapper>
          <Content>{t(`${DIALOG_PREFIX}uncalculated_warning_${type}`)}</Content>
          <ButtonsWrapper>
            {prepareButtonsConfig.map(
              ({ text, onClick, hidden, width, disabled }, i) =>
                !hidden && (
                  <CustomButton
                    key={i}
                    width={width}
                    onClick={onClick}
                    type="secondary"
                    disabled={isDisabledBtns || disabled}
                  >
                    {t(`${DIALOG_PREFIX}${text}`)}
                  </CustomButton>
                )
            )}
          </ButtonsWrapper>
        </Fragment>
      )}
    </CustomDialog>
  )
}

export default ExportBasketDialog
