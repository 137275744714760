import {
  SETTINGS_FREEMOL_INC,
  SETTINGS_FREEREACTIONS_INC,
  SETTINGS_FREEREACTIONS_RESET,
  SETTINGS_FREESEARCH_INC,
  SETTINGS_FREESEARCH_RESET,
  SETTINGS_MOLSIZE,
  SETTINGS_SIDEBAR_TOGGLE,
  SETTINGS_SET_SHOW_PUBLIC_PDF,
  SETTINGS_LANG_CHANGE,
  SETTINGS_SHOW_TIPS_TOGGLE,
  SETTINGS_SET_BASKET_TYPE,
  SETTINGS_SET_SESSION_INTERRUPTED,
} from '../constants/settings'

const FREEREACTIONS_INITIAL = 0
const FREESEARCH_INITIAL = 0

export const initialState = {
  viewSize: 2,
  withData: true,
  isSidebarCollapsed: false,
  freeMol: 0,
  freeReactions: FREEREACTIONS_INITIAL,
  freeSearch: FREESEARCH_INITIAL,
  basketType: 'thematic',
  showPublicPdf: true,
  lang: 'en',
  showTips: true,
  isSessionInterrupted: false,
}

const settingsReducer = (state = initialState, payload) => {
  switch (payload.type) {
    case SETTINGS_MOLSIZE: {
      return { ...state, viewSize: payload.value }
    }
    case SETTINGS_FREEMOL_INC:
      return { ...state, freeMol: state.freeMol + 1 || 1 }
    case SETTINGS_FREEREACTIONS_INC:
      return { ...state, freeReactions: state.freeReactions + 1 || 1 }
    case SETTINGS_FREEREACTIONS_RESET:
      return { ...state, freeReactions: FREEREACTIONS_INITIAL }
    case SETTINGS_FREESEARCH_INC:
      return { ...state, freeSearch: state.freeSearch + 1 || 1 }
    case SETTINGS_FREESEARCH_RESET:
      return { ...state, freeSearch: FREESEARCH_INITIAL }
    case SETTINGS_SIDEBAR_TOGGLE:
      return { ...state, isSidebarCollapsed: !state.isSidebarCollapsed }
    case SETTINGS_SET_BASKET_TYPE:
      return { ...state, basketType: payload.basketType }
    case SETTINGS_SET_SHOW_PUBLIC_PDF:
      return { ...state, showPublicPdf: payload.showPublicPdf }
    case SETTINGS_LANG_CHANGE:
      return { ...state, lang: payload.lang }
    case SETTINGS_SHOW_TIPS_TOGGLE:
      return { ...state, showTips: payload.value }
    case SETTINGS_SET_SESSION_INTERRUPTED:
      return { ...state, isSessionInterrupted: payload.value }
    default:
      return state
  }
}

export default settingsReducer
