import axios from 'axios'

import { API_URL, API_V2_URL } from '../config/config'
import { readStorage } from '../utils/storage/storage'
import { LIMITS_ERROR_CODES, LIMITS_ERRORS_TYPES } from 'config/licenses'
import { addNotification, hideNotifications } from 'store/actions/notifications'
import { v4 as uuidv4 } from 'uuid'
import { store as reduxStore } from 'store'
import { getLicenseNotificationText } from 'utils/licenses/getLicenseNotificationText'

const apiHost = API_URL
const apiV2Host = API_V2_URL

export const getHeaders = (tkn) => {
  const headers = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
  }

  const token = readStorage('s_token')
  if (token) {
    headers['Authorization'] = `Bearer ${token}`
  }
  if (tkn) {
    headers['Authorization'] = `Bearer ${tkn}`
  }
  return headers
}

const rest = axios.create({
  baseURL: apiHost,
})
const restV2 = axios.create({
  baseURL: apiV2Host,
})

export const axiosInstances = [rest, restV2]

export const getLimitedMethod =
  (method) =>
  async (...args) => {
    try {
      const res = await method(...args)
      return res
    } catch (e) {
      if (
        e?.response &&
        LIMITS_ERROR_CODES.includes(e.response.status) &&
        LIMITS_ERRORS_TYPES.includes(e.response.data?.result?.error_type)
      ) {
        if (
          e.response.data.result.error_type === 'period.day' ||
          e.response.data.result.error_type === 'period.month'
        ) {
          const notifications = reduxStore.getState().notifications
          if (
            notifications.notificationsList[
              notifications.notificationsList.length - 1
            ]?.name ===
            `notifications.limits.${e.response.data.result.error_type}`
          ) {
            notifications.isHidden && reduxStore.dispatch(hideNotifications())
            return
          }
        }
        const id = uuidv4()
        const notify = {
          id,
          name: `notifications.limits.${e.response.data.result.error_type}`,
          text: getLicenseNotificationText(
            e.response.data.result.error_type,
            e.response.data.result.error_message,
            args[0]
          ),
          notification_type: 'warning',
          autoRemove: false,
        }
        reduxStore.dispatch(addNotification(notify))
        return
      }

      throw e
    }
  }

export const basicFetch = (endpoint, apiVersion = 1) =>
  (apiVersion === 2 ? restV2 : rest).get(endpoint, {
    headers: getHeaders(),
    ...(endpoint.includes('type=xlsx') ? { responseType: 'arraybuffer' } : {}),
  })

export const fetch = getLimitedMethod(basicFetch)

export const basicStore = (endpoint, data, headers, options = {}) =>
  rest.post(endpoint, data, {
    headers: { ...getHeaders(), ...headers },
    ...options,
  })

export const store = getLimitedMethod(basicStore)

export const basicStoreV2 = (endpoint, data, headers, options = {}) =>
  restV2.post(endpoint, data, {
    headers: { ...getHeaders(), ...headers },
    ...options,
  })

export const storeV2 = getLimitedMethod(basicStoreV2)

export function basicUpdate(endpoint, data) {
  return rest.put(endpoint, data, {
    headers: getHeaders(),
  })
}
export function basicUpdateV2(endpoint, data) {
  return restV2.put(endpoint, data, {
    headers: getHeaders(),
  })
}

export const update = getLimitedMethod(basicUpdate)
export const updateV2 = getLimitedMethod(basicUpdateV2)

export function basicPatchV2(endpoint, data) {
  return restV2.patch(endpoint, data, {
    headers: getHeaders(),
  })
}

export const patchV2 = getLimitedMethod(basicPatchV2)

export function basicDestroy(endpoint, data) {
  return rest.delete(endpoint, {
    headers: getHeaders(),
    data,
  })
}

export const destroy = getLimitedMethod(basicDestroy)

export function basicDestroyV2(endpoint, data) {
  return restV2.delete(endpoint, {
    headers: getHeaders(),
    data,
  })
}

export const destroyV2 = getLimitedMethod(basicDestroyV2)

export default rest
