import styled from 'styled-components'

export const NotificationWarningContainer = styled.div`
  display: flex;
  flex-direction: column;
`

export const NotificationHeader = styled.div`
  display: flex;
  column-gap: 0.5rem;
  align-items: center;

  & > svg {
    width: 1.5rem;
    height: 1.5rem;
  }

  & > span {
    line-height: 1rem;
    font-size: ${({ theme }) => theme.text[12]};
    font-weight: ${({ theme }) => theme.fontWeight.semibold};
    color: ${({ theme }) => theme.colors.text.primary};
    width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    -webkit-line-clamp: 2;
    width: 19.875rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
`

export const NotificationWarningBody = styled.div`
  display: flex;
  padding: 1rem;
  row-gap: 0.5rem;
  flex-direction: column;
`
export const HeaderText = styled.span`
  & > span {
    color: ${({ theme }) => theme.colors.text.accentPrimary};
  }
`
