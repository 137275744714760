import { takeEvery, put, select, delay } from 'redux-saga/effects'
import axios from 'axios'
import {
  SPECTRA_MS_FAIL,
  SPECTRA_MS_SUCCESS,
  SPECTRA_MS_INIT,
  SPECTRA_NMR_SUCCESS,
  SPECTRA_NMR_FAIL,
  SPECTRA_NMR_INIT,
  SPECTRA_INFRARED_INIT,
  SPECTRA_INFRARED_SUCCESS,
  SPECTRA_INFRARED_FAIL,
} from 'store/constants/spectra'
import { API_URL } from 'config/config'
import { fetch, getHeaders } from 'services/rest'

function* checkIsTaskReady(id) {
  let status = 'running'
  let currentTaskId
  let spectraResponse
  while (status === 'running') {
    const tasks = yield fetch(`/userTasksStatus`)
    const task = tasks?.data?.find((task) => id === task.uuid)

    if (task) {
      currentTaskId = task
      if (task?.status === 'ok') {
        status = 'ok'
        spectraResponse = yield fetch(`/userTasksStatus/${id}`)
      } else {
        if (task?.status === 'error') {
          throw new Error('error_ms')
        }
      }
    }
    if (currentTaskId && !task) {
      currentTaskId = 'canceled'
      status = 'canceled'
    }

    yield delay(2000)
  }
  return { spectraResponse, currentTaskId }
}

function* spectraMsGet({
  data: { smiles, adduct_type, m_z_round, percent_round },
}) {
  let currentTaskId
  try {
    const response = yield axios.post(
      `${API_URL}predict-cfmid`,
      {
        smiles,
        adduct_type,
      },
      {
        headers: getHeaders(),
      }
    )

    let msResponse
    if (response?.status === 200) {
      let checkResult = yield* checkIsTaskReady(response?.data?.task_uuid)

      currentTaskId = checkResult.currentTaskId
      msResponse = checkResult.spectraResponse
    }
    if (!msResponse?.data?.result) throw new Error()

    const isotopsResponse = yield axios.post(
      `${API_URL}run-task`,
      {
        service: 'isotops',
        params: { smiles, m_z_round, percent_round },
      },
      {
        headers: getHeaders(),
      }
    )

    yield put({
      type: SPECTRA_MS_SUCCESS,
      data: {
        ...msResponse.data.result,
        isotopic_distribution: [...isotopsResponse.data.result],
      },
    })
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put({
        type: SPECTRA_MS_SUCCESS,
        data: {},
      })
    } else if (error) {
      yield put({
        type: SPECTRA_MS_FAIL,
        error: error?.response?.data?.detail || error?.message || 'error',
      })
    }
  }
}

function* spectraNmrGet({ data: { smiles, method } }) {
  let currentTaskId
  try {
    const response = yield axios.post(
      `${API_URL}run-task`,
      {
        input: {
          smiles,
        },
        service: 'nmr-hosegen',
        nocache: false,
        type: 'delayed',
        method,
      },
      {
        headers: getHeaders(),
      }
    )

    let nmrResponse
    if (response?.status === 200) {
      let checkResult = yield* checkIsTaskReady(response?.data?.task_uuid)

      currentTaskId = checkResult.currentTaskId
      nmrResponse = checkResult.spectraResponse
    }

    if (!nmrResponse?.data?.result) throw new Error()

    yield put({
      type: SPECTRA_NMR_SUCCESS,
      data: {
        ...nmrResponse.data.result,
      },
    })
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put({
        type: SPECTRA_NMR_SUCCESS,
        data: {},
      })
    } else if (error) {
      yield put({
        type: SPECTRA_NMR_FAIL,
        error: error?.response?.data?.detail || error?.message || 'error',
      })
    }
  }
}

function* spectraInfraredGet({ smiles }) {
  let currentTaskId
  try {
    const method = yield select(
      (state) => state.spectra.infrared.shootingMethod
    )
    const response = yield axios.post(
      `${API_URL}run-task`,
      {
        service: 'ir_spectr',
        params: { smiles, adduct_type: method.value },
        type: 'delayed',
      },
      {
        headers: getHeaders(),
      }
    )

    let irResponse
    if (response?.status === 200) {
      let checkResult = yield* checkIsTaskReady(response?.data?.task_uuid)

      currentTaskId = checkResult.currentTaskId
      irResponse = checkResult.spectraResponse
    }
    if (!irResponse?.data?.result) throw new Error()

    yield put({
      type: SPECTRA_INFRARED_SUCCESS,
      data: [...irResponse.data.result],
    })
  } catch (error) {
    if (error && currentTaskId === 'canceled') {
      yield put({
        type: SPECTRA_INFRARED_SUCCESS,
        data: [],
      })
    } else if (error) {
      yield put({
        type: SPECTRA_INFRARED_FAIL,
        error: error?.response?.data?.detail || error?.message || 'error',
      })
    }
  }
}

export function* spectraMsGetWatcher() {
  yield takeEvery(SPECTRA_MS_INIT, spectraMsGet)
}

export function* spectraNmrGetWatcher() {
  yield takeEvery(SPECTRA_NMR_INIT, spectraNmrGet)
}

export function* spectraInfraredGetWatcher() {
  yield takeEvery(SPECTRA_INFRARED_INIT, spectraInfraredGet)
}
