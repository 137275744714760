import { useTheme } from 'styled-components'
import { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import CustomScrollbar from 'components/CustomScrollbar'
import CustomInput from 'components/common/customInput'
import { OutboundClick } from 'components/common/outboundClick/outboundClick'

import {
  SelectContainer,
  SelectList,
  SelectItem,
  ArrowDownSvg,
  IconWrapper,
  InputWrapper,
  SelectedLabel,
  SelectItems,
} from './index.style'

const CustomSelect = ({
  multiple = false,
  selectedValue,
  onChange,
  options,
  disabled,
  disabledOpening = false,
  width,
  withTranslation = true,
  withSelectListUp = false,
  withWhiteBackground,
  withWhiteInputBackground,
  id = '',
  emptyLabel = '',
  withSearchByValue = false,
  withSearchByLabel = false,
  inputPlaceholder,
  padding,
  className,
  hideIcon = false,
}) => {
  const theme = useTheme()
  const listRef = useRef(null)
  const { t } = useTranslation()
  const [inputValue, setInputValue] = useState('')

  const filteredOptions = useMemo(
    () =>
      inputValue
        ? options.filter((el) =>
            withSearchByValue
              ? el.value.toLowerCase().includes(inputValue.toLowerCase())
              : t(el.label).toLowerCase().includes(inputValue.toLowerCase())
          )
        : options,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [options, inputValue]
  )

  const [isOpen, setIsOpen] = useState(false)

  const getSelectedLabel = () => {
    if (
      selectedValue?.value !== null &&
      selectedValue?.value !== undefined &&
      selectedValue?.value !== ''
    )
      return (
        <SelectedLabel>
          {withTranslation ? t(selectedValue.label) : selectedValue.label}
        </SelectedLabel>
      )
    else
      return (
        <SelectedLabel color={theme.colors.text.tertiary}>
          {withTranslation && emptyLabel ? t(emptyLabel) : emptyLabel}
        </SelectedLabel>
      )
  }

  const selectOption = (e, option) => {
    e.stopPropagation()
    if (multiple) {
      if (selectedValue.includes(option)) {
        onChange(selectedValue.filter((o) => o !== option))
      } else {
        onChange([...selectedValue, option])
      }
    } else {
      if (option.value !== selectedValue.value) onChange(option)
    }
    setIsOpen(false)
  }

  return (
    <OutboundClick onClick={() => setIsOpen(false)} className="select-outbound">
      <SelectContainer
        className={`select-container ${className || ''}`}
        isOpen={isOpen}
        onClick={(e) => {
          if (
            e?.target?.classList?.value.includes('crollbarsCustom') ||
            e?.target?.tagName === 'INPUT' ||
            e?.target?.closest('#clear')
          ) {
            return
          }
          !disabled && !disabledOpening && setIsOpen((prev) => !prev)
        }}
        tabIndex={0}
        disabled={disabled}
        disabledOpening={disabledOpening}
        width={width}
        id={id}
        withWhiteBackground={withWhiteBackground}
      >
        {getSelectedLabel()}

        {isOpen && !disabledOpening && (
          <SelectList withSelectListUp={withSelectListUp} padding={padding}>
            {(withSearchByLabel || withSearchByValue) && (
              <InputWrapper
                withValues={options.length && filteredOptions.length}
              >
                <CustomInput
                  value={inputValue}
                  onChange={setInputValue}
                  withClearButton
                  placeholder={inputPlaceholder}
                  withWhiteBackground={
                    withWhiteBackground || withWhiteInputBackground
                  }
                />
              </InputWrapper>
            )}
            <SelectItems>
              <CustomScrollbar
                fixedHeight={false}
                trigger={filteredOptions?.length}
              >
                <div className="custom-scroll-wrapper" ref={listRef}>
                  {filteredOptions.length ? (
                    filteredOptions.map((option, index) => {
                      return (
                        <SelectItem
                          onClick={(e) => {
                            selectOption(e, option)
                          }}
                          key={`${option.value}-${index}`}
                          selected={option.value === selectedValue.value}
                        >
                          {withTranslation ? t(option.label) : option.label}
                        </SelectItem>
                      )
                    })
                  ) : (
                    <div style={{ padding: '0.625rem 0.75rem' }}>
                      {options.length
                        ? t('dataset_table.value_not_found')
                        : t('dataset_table.no_values')}
                    </div>
                  )}
                </div>
              </CustomScrollbar>
            </SelectItems>
          </SelectList>
        )}
        {!hideIcon && (
          <IconWrapper {...{ isOpen, withWhiteBackground }}>
            <ArrowDownSvg iconType="arrowDown" open={isOpen} size="1rem" />
          </IconWrapper>
        )}
      </SelectContainer>
    </OutboundClick>
  )
}

export default CustomSelect
