import styled from 'styled-components'

export const Wrapper = styled.div`
  position: relative;
  width: 49.563rem;
  height: 4.5rem;
  // padding: ${({ withBottom }) =>
    withBottom ? '1.5rem 2rem' : '1.5rem 2rem'};
  padding: 1.5rem 2rem;
  display: flex;
  align-items: center !important;
  justify-content: space-between;
  border-top: ${({ borderTop }) =>
    borderTop && '1px solid rgba(230, 235, 241, 1)'};
  border-bottom: ${({ borderBottom }) =>
    borderBottom && '1px solid rgba(230, 235, 241, 1)'};
  text-align: center;
  color: ${({ theme }) => theme.colors.text.white};
  svg {
    color: ${({ theme }) => theme.colors.text.white};
  }
`
export const LogoContainer = styled.img`
  display: block;
  margin-right: auto;
  height: 1.7rem !important;
  width: max-content;
`
export const ContactsContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
  // margin-bottom: 1.5rem;
  justify-content: space-between;
`
export const Contact = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: start;
  width: 5rem;
`

export const SocialNetworksContainer = styled.div`
  display: flex;
`

export const IconsWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 0.75rem;
  height: 100%;
`

export const IconLink = styled.a`
  text-decoration: none;
  display: inline-flex;
  align-items: center;
`
export const WatermarkWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
`
