import {
  UNCOMPARE_STRUCTURE_INIT,
  ADD_STRUCTURE_TO_COMPARE_INIT,
  GET_STRUCTURES_CALCULATION_INIT,
  GET_STRUCTURES_COMPARISON_LIST_INIT,
  CLEAR_STRUCTURES_COMPARISON_LIST_INIT,
  ADD_BLUR_NON_APPLICABLE_PROPERTY,
} from 'store/constants/structuresCompare'

export const getStructuresComparisonList = () => {
  return {
    type: GET_STRUCTURES_COMPARISON_LIST_INIT,
  }
}

export const addStructureToCompare = (id, smiles) => {
  return {
    type: ADD_STRUCTURE_TO_COMPARE_INIT,
    id,
    smiles,
  }
}

export const uncompareStructure = (baseID, needNotification = true) => {
  return {
    type: UNCOMPARE_STRUCTURE_INIT,
    baseID,
    needNotification,
  }
}

export const clearStructuresComparisonList = () => {
  return {
    type: CLEAR_STRUCTURES_COMPARISON_LIST_INIT,
  }
}

export const getStructuresCalculation = () => {
  return {
    type: GET_STRUCTURES_CALCULATION_INIT,
  }
}

export const addBlurNonApplicableProperty = (value) => {
  return {
    type: ADD_BLUR_NON_APPLICABLE_PROPERTY,
    value,
  }
}
