import styled from 'styled-components'

import CustomButton from 'components/common/customButton'
import { Label } from 'components/common/text/index.style'

export const UploadedFileContainer = styled.div`
  gap: 1rem;
  padding: 1rem;
  display: flex;
  position: relative;
  flex-direction: column;
  border-radius: 0.75rem;
  background: ${({ theme }) => theme.colors.backgrounds.secondary};

  .delete-file-btn {
    opacity: 0;
    user-select: none;
  }

  &:hover .delete-file-btn {
    opacity: 1;
  }
`

export const FileInfo = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
`
export const FileName = styled(Label)`
  width: 100%;
  overflow: hidden;
  max-height: 2rem;
  white-space: wrap;
  display: -webkit-box;
  word-break: break-word;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

export const NameWithInfo = styled.div`
  gap: 0.25rem;
  display: flex;
  flex-direction: column;
`

export const TypeWithSize = styled.div`
  gap: 0.25rem;
  display: flex;
  align-items: center;
`

export const DeleteBtn = styled(CustomButton)`
  position: absolute;
  right: 0.25rem;
  top: 0.25rem;
  height: 1.75rem;
  border-radius: 0.5rem;
  padding: 0.375rem;
  transition: all 0.3s ease;
  & > svg {
    color: ${({ theme }) => theme.colors.icons.tertiary};
  }

  &:hover > svg {
    color: ${({ theme }) => theme.colors.statuses.error};
  }
`
