import { memo } from 'react'
import { CustomTagElement } from './index.style'

const CustomTag = memo(
  ({
    onClick = () => {},
    children,
    className,
    type = 'grey',
    width = '',
    height = '',
    isIconInteractable = false,
    defaultColor = '',
    defaultPadding = '',
    isBordered = false,
    isPointer = true,
    ...props
  }) => (
    <CustomTagElement
      onClick={onClick}
      type={type}
      className={className}
      width={width}
      height={height}
      isIconInteractable={isIconInteractable}
      defaultColor={defaultColor}
      defaultPadding={defaultPadding}
      padding={props.padding}
      radius={props.radius}
      fontSize={props.fontSize}
      isPointer={isPointer}
      isBordered={isBordered}
      {...props}
    >
      {children}
    </CustomTagElement>
  )
)

CustomTag.displayName = 'CustomTag'
export default CustomTag
