import { CustomButtonElement } from './index.style'

const CustomButton = ({
  onClick,
  children,
  active = false,
  disabled = false,
  defaultColor = '',
  defaultPadding = '',
  width = 'max-content',
  minWidth,
  type,
  height = '',
  minHeight,
  gap = '0px',
  className,
  onMouseOver = () => {},
  onMouseLeave = () => {},
  size = 'standard',
  borderRadius = '',
  bgColor,
  withIcon,
  noAnimation = false,
  id,
  ...props
}) => (
  <CustomButtonElement
    {...(id ? { id } : {})}
    onClick={disabled ? undefined : onClick}
    disabled={disabled}
    active={active}
    width={width}
    minWidth={minWidth}
    minHeight={minHeight}
    height={height}
    type={type}
    className={className}
    onMouseOver={onMouseOver}
    onMouseLeave={onMouseLeave}
    gap={gap}
    size={size}
    defaultColor={defaultColor}
    defaultPadding={defaultPadding}
    borderRadius={borderRadius}
    bgColor={bgColor}
    withIcon={withIcon}
    noAnimation={noAnimation}
    {...props}
  >
    {children}
  </CustomButtonElement>
)

export default CustomButton
